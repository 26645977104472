import React, { useEffect, useRef } from 'react';

interface Node {
  x: number;
  y: number;
  radius: number;
  connections: Node[];
  velocity: { x: number; y: number };
  activity: number;
  pulsePhase: number;
  type: 'primary' | 'secondary' | 'hub';
}

interface DataFlow {
  startNode: Node;
  endNode: Node;
  progress: number;
  speed: number;
  size: number;
  opacity: number;
  type: 'data' | 'energy' | 'signal';
}

export default function NeuralBackground() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const nodesRef = useRef<Node[]>([]);
  const dataFlowsRef = useRef<DataFlow[]>([]);
  const animationFrameRef = useRef<number>();

  useEffect(() => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d', { alpha: true });
    if (!ctx) return;

    const initializeNodes = () => {
      const nodes: Node[] = [];
      // Increase number of nodes significantly
      const numNodes = Math.floor(window.innerWidth * window.innerHeight / 20000);
      const hubCount = Math.floor(numNodes * 0.15); // 15% of nodes are hubs

      // Create hub nodes first
      for (let i = 0; i < hubCount; i++) {
        nodes.push({
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height,
          radius: 4 + Math.random() * 3, // Larger radius for hubs
          connections: [],
          velocity: {
            x: (Math.random() - 0.5) * 0.1,
            y: (Math.random() - 0.5) * 0.1
          },
          activity: 0.8 + Math.random() * 0.2,
          pulsePhase: Math.random() * Math.PI * 2,
          type: 'hub'
        });
      }

      // Create regular nodes
      for (let i = hubCount; i < numNodes; i++) {
        nodes.push({
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height,
          radius: 1.5 + Math.random() * 1.5,
          connections: [],
          velocity: {
            x: (Math.random() - 0.5) * 0.2,
            y: (Math.random() - 0.5) * 0.2
          },
          activity: 0.5 + Math.random() * 0.5,
          pulsePhase: Math.random() * Math.PI * 2,
          type: Math.random() > 0.5 ? 'primary' : 'secondary'
        });
      }

      // Create more complex connection patterns
      nodes.forEach(node => {
        const maxConnections = node.type === 'hub' ? 8 : 4;
        const connectionRange = node.type === 'hub' ? canvas.width / 3 : canvas.width / 4;
        
        const nearbyNodes = nodes
          .filter(n => n !== node)
          .filter(n => {
            const dist = Math.hypot(n.x - node.x, n.y - node.y);
            return dist < connectionRange;
          })
          .sort((a, b) => {
            const distA = Math.hypot(a.x - node.x, a.y - node.y);
            const distB = Math.hypot(b.x - node.x, b.y - node.y);
            return distA - distB;
          })
          .slice(0, maxConnections);

        node.connections = nearbyNodes;
      });

      nodesRef.current = nodes;
      dataFlowsRef.current = [];
    };

    const createDataFlow = () => {
      const nodes = nodesRef.current;
      if (nodes.length < 2) return;

      // Prefer hub nodes as start points
      const hubNodes = nodes.filter(n => n.type === 'hub');
      const startNode = Math.random() > 0.3 ? 
        hubNodes[Math.floor(Math.random() * hubNodes.length)] || nodes[Math.floor(Math.random() * nodes.length)] :
        nodes[Math.floor(Math.random() * nodes.length)];

      const endNode = startNode.connections[Math.floor(Math.random() * startNode.connections.length)];
      if (!endNode) return;

      const flowTypes: DataFlow['type'][] = ['data', 'energy', 'signal'];
      const type = flowTypes[Math.floor(Math.random() * flowTypes.length)];

      dataFlowsRef.current.push({
        startNode,
        endNode,
        progress: 0,
        speed: 0.003 + Math.random() * 0.004,
        size: 2 + Math.random() * 2,
        opacity: 0.5 + Math.random() * 0.5,
        type
      });
    };

    const drawNode = (node: Node, ctx: CanvasRenderingContext2D, time: number) => {
      const pulse = Math.sin(time * 2 + node.pulsePhase) * 0.5 + 0.5;
      const baseOpacity = node.type === 'hub' ? 0.6 : (0.3 + pulse * 0.2);
      
      // Enhanced node glow
      const glowRadius = node.type === 'hub' ? node.radius * 6 : node.radius * 4;
      const gradient = ctx.createRadialGradient(
        node.x, node.y, 0,
        node.x, node.y, glowRadius
      );

      const color = node.type === 'hub' ? 
        [0, 168, 204] : 
        node.type === 'primary' ? 
          [0, 204, 168] : 
          [0, 140, 204];

      gradient.addColorStop(0, `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${baseOpacity})`);
      gradient.addColorStop(0.5, `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${baseOpacity * 0.5})`);
      gradient.addColorStop(1, 'rgba(0, 168, 204, 0)');

      ctx.beginPath();
      ctx.arc(node.x, node.y, glowRadius, 0, Math.PI * 2);
      ctx.fillStyle = gradient;
      ctx.fill();

      // Node core with enhanced effect
      ctx.beginPath();
      ctx.arc(node.x, node.y, node.radius, 0, Math.PI * 2);
      ctx.fillStyle = `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${baseOpacity + 0.3})`;
      ctx.fill();

      // Additional detail for hub nodes
      if (node.type === 'hub') {
        ctx.beginPath();
        ctx.arc(node.x, node.y, node.radius * 1.5, 0, Math.PI * 2);
        ctx.strokeStyle = `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${0.2 + pulse * 0.1})`;
        ctx.lineWidth = 1;
        ctx.stroke();
      }
    };

    const drawConnection = (start: Node, end: Node, ctx: CanvasRenderingContext2D, time: number) => {
      const distance = Math.hypot(end.x - start.x, end.y - start.y);
      if (distance > canvas.width / 3) return;

      const baseOpacity = Math.max(0, 0.2 * (1 - distance / (canvas.width / 3)));
      const pulse = Math.sin(time * 2 + start.pulsePhase) * 0.1 + 0.9;
      
      ctx.beginPath();
      ctx.moveTo(start.x, start.y);
      ctx.lineTo(end.x, end.y);
      
      // Enhanced connection style based on node types
      if (start.type === 'hub' || end.type === 'hub') {
        ctx.strokeStyle = `rgba(0, 168, 204, ${baseOpacity * pulse * 1.5})`;
        ctx.lineWidth = 1;
      } else {
        ctx.strokeStyle = `rgba(0, 168, 204, ${baseOpacity * pulse})`;
        ctx.lineWidth = 0.5;
      }
      
      ctx.stroke();
    };

    const drawDataFlow = (flow: DataFlow, ctx: CanvasRenderingContext2D, time: number) => {
      const { startNode, endNode, progress, size, opacity, type } = flow;
      
      const x = startNode.x + (endNode.x - startNode.x) * progress;
      const y = startNode.y + (endNode.y - startNode.y) * progress;

      const pulse = Math.sin(time * 4 + startNode.pulsePhase) * 0.2 + 0.8;
      
      // Color based on flow type
      const color = type === 'data' ? 
        [0, 168, 204] : 
        type === 'energy' ? 
          [0, 204, 168] : 
          [0, 140, 204];

      const gradient = ctx.createRadialGradient(x, y, 0, x, y, size * 4);
      gradient.addColorStop(0, `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${opacity * pulse})`);
      gradient.addColorStop(0.5, `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${opacity * 0.5 * pulse})`);
      gradient.addColorStop(1, 'rgba(0, 168, 204, 0)');

      ctx.beginPath();
      ctx.arc(x, y, size * 4, 0, Math.PI * 2);
      ctx.fillStyle = gradient;
      ctx.fill();

      // Core of the data flow
      ctx.beginPath();
      ctx.arc(x, y, size, 0, Math.PI * 2);
      ctx.fillStyle = `rgba(255, 255, 255, ${opacity * pulse})`;
      ctx.fill();
    };

    const updateNodes = () => {
      nodesRef.current.forEach(node => {
        node.x += node.velocity.x;
        node.y += node.velocity.y;

        if (node.x < 0 || node.x > canvas.width) {
          node.velocity.x *= -0.8;
          node.x = Math.max(0, Math.min(canvas.width, node.x));
        }
        if (node.y < 0 || node.y > canvas.height) {
          node.velocity.y *= -0.8;
          node.y = Math.max(0, Math.min(canvas.height, node.y));
        }

        node.velocity.x *= 0.99;
        node.velocity.y *= 0.99;
      });
    };

    const updateDataFlows = () => {
      dataFlowsRef.current = dataFlowsRef.current.filter(flow => {
        flow.progress += flow.speed;
        return flow.progress <= 1;
      });

      // Increase frequency of data flow creation
      if (Math.random() < 0.1) {
        createDataFlow();
      }
    };

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      const time = Date.now() * 0.001;

      // Draw connections
      nodesRef.current.forEach(node => {
        node.connections.forEach(connection => {
          drawConnection(node, connection, ctx, time);
        });
      });

      // Draw nodes
      nodesRef.current.forEach(node => {
        drawNode(node, ctx, time);
      });

      // Draw data flows
      dataFlowsRef.current.forEach(flow => {
        drawDataFlow(flow, ctx, time);
      });

      updateNodes();
      updateDataFlows();

      animationFrameRef.current = requestAnimationFrame(animate);
    };

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      initializeNodes();
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    animate();

    return () => {
      window.removeEventListener('resize', handleResize);
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      className="fixed inset-0 pointer-events-none opacity-30"
    />
  );
}