import React from 'react';

interface DPOInquiryProps {
  formData: {
    dataSubject: string;
    requestType: string;
    requestDetails: string;
  };
  handleChange: (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>) => void;
}

export default function DPOInquiry({ formData, handleChange }: DPOInquiryProps) {
  return (
    <div className="glass-card p-6 rounded-xl backdrop-blur-md">
      <h2 className="text-2xl font-bold text-white mb-6">Data Protection Inquiry</h2>
      <div className="space-y-6">
        <div>
          <label htmlFor="dataSubject" className="block text-sm font-medium text-gray-300 mb-1">
            Are you the data subject?*
          </label>
          <select
            id="dataSubject"
            name="dataSubject"
            value={formData.dataSubject}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 rounded-md bg-gray-800 border border-gray-700 text-white focus:ring-2 focus:ring-teal-500 focus:border-transparent"
          >
            <option value="">Please select</option>
            <option value="yes">Yes, I am the data subject</option>
            <option value="no">No, I'm acting on behalf of the data subject</option>
          </select>
        </div>

        <div>
          <label htmlFor="requestType" className="block text-sm font-medium text-gray-300 mb-1">
            Type of Request*
          </label>
          <select
            id="requestType"
            name="requestType"
            value={formData.requestType}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 rounded-md bg-gray-800 border border-gray-700 text-white focus:ring-2 focus:ring-teal-500 focus:border-transparent"
          >
            <option value="">Select request type</option>
            <option value="access">Access to Personal Data</option>
            <option value="rectification">Rectification of Data</option>
            <option value="erasure">Erasure of Data</option>
            <option value="restriction">Restriction of Processing</option>
            <option value="portability">Data Portability</option>
            <option value="objection">Object to Processing</option>
            <option value="breach">Report Data Breach</option>
            <option value="other">Other GDPR Related Query</option>
          </select>
        </div>

        <div>
          <label htmlFor="requestDetails" className="block text-sm font-medium text-gray-300 mb-1">
            Please provide details about your request*
          </label>
          <textarea
            id="requestDetails"
            name="requestDetails"
            value={formData.requestDetails}
            onChange={handleChange}
            required
            rows={6}
            className="w-full px-4 py-2 rounded-md bg-gray-800 border border-gray-700 text-white focus:ring-2 focus:ring-teal-500 focus:border-transparent"
            placeholder="Please provide specific details about your data protection request..."
          />
        </div>
      </div>
    </div>
  );
}