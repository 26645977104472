import { useCallback } from 'react';
import { Location, NavigateFunction } from 'react-router-dom';

interface UseScrollNavigationProps {
  location: Location;
  navigate: NavigateFunction;
  setIsMenuOpen: (isOpen: boolean) => void;
}

export function useScrollNavigation({ 
  location, 
  navigate, 
  setIsMenuOpen 
}: UseScrollNavigationProps) {
  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const handleHomeClick = useCallback(() => {
    if (location.pathname === '/') {
      scrollToTop();
    } else {
      navigate('/');
    }
    setIsMenuOpen(false);
  }, [location.pathname, navigate, setIsMenuOpen, scrollToTop]);

  const scrollToSection = useCallback((sectionId: string) => {
    setIsMenuOpen(false);
    
    if (location.pathname !== '/') {
      navigate('/', { state: { scrollTo: sectionId } });
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        // Set offset to 0 pixels for exact section alignment
        const offset = 0;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  }, [location.pathname, navigate, setIsMenuOpen]);

  return {
    handleHomeClick,
    scrollToSection
  };
}