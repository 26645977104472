import React from 'react';
import type { CalculatorInputs } from './types';

interface CalculatorFormProps {
  inputs: CalculatorInputs;
  setInputs: (inputs: CalculatorInputs) => void;
}

export default function CalculatorForm({ inputs, setInputs }: CalculatorFormProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setInputs(prev => ({
      ...prev,
      [name]: name === 'industry' ? value : Number(value)
    }));
  };

  return (
    <div className="glass-card p-8 rounded-xl backdrop-blur-md">
      <h2 className="text-2xl font-bold text-white mb-8">Business Information</h2>
      
      <div className="space-y-6">
        <div>
          <label htmlFor="employees" className="block text-sm font-medium text-gray-300 mb-2">
            Number of Employees
          </label>
          <input
            type="number"
            id="employees"
            name="employees"
            value={inputs.employees}
            onChange={handleChange}
            min="1"
            className="w-full px-4 py-2 rounded-md bg-gray-800 border border-gray-700 text-white focus:ring-2 focus:ring-teal-500 focus:border-transparent"
          />
        </div>

        <div>
          <label htmlFor="avgSalary" className="block text-sm font-medium text-gray-300 mb-2">
            Average Annual Salary (£)
          </label>
          <input
            type="number"
            id="avgSalary"
            name="avgSalary"
            value={inputs.avgSalary}
            onChange={handleChange}
            min="1"
            className="w-full px-4 py-2 rounded-md bg-gray-800 border border-gray-700 text-white focus:ring-2 focus:ring-teal-500 focus:border-transparent"
          />
        </div>

        <div>
          <label htmlFor="processTime" className="block text-sm font-medium text-gray-300 mb-2">
            Time Spent on Manual Processes (hours/week)
          </label>
          <input
            type="number"
            id="processTime"
            name="processTime"
            value={inputs.processTime}
            onChange={handleChange}
            min="0"
            max="168"
            className="w-full px-4 py-2 rounded-md bg-gray-800 border border-gray-700 text-white focus:ring-2 focus:ring-teal-500 focus:border-transparent"
          />
        </div>

        <div>
          <label htmlFor="errorRate" className="block text-sm font-medium text-gray-300 mb-2">
            Current Error Rate (%)
          </label>
          <input
            type="number"
            id="errorRate"
            name="errorRate"
            value={inputs.errorRate}
            onChange={handleChange}
            min="0"
            max="100"
            className="w-full px-4 py-2 rounded-md bg-gray-800 border border-gray-700 text-white focus:ring-2 focus:ring-teal-500 focus:border-transparent"
          />
        </div>

        <div>
          <label htmlFor="industry" className="block text-sm font-medium text-gray-300 mb-2">
            Industry
          </label>
          <select
            id="industry"
            name="industry"
            value={inputs.industry}
            onChange={handleChange}
            className="w-full px-4 py-2 rounded-md bg-gray-800 border border-gray-700 text-white focus:ring-2 focus:ring-teal-500 focus:border-transparent"
          >
            <option value="general">General Business</option>
            <option value="finance">Financial Services</option>
            <option value="healthcare">Healthcare</option>
            <option value="logistics">Logistics</option>
            <option value="manufacturing">Manufacturing</option>
            <option value="retail">Retail</option>
          </select>
        </div>
      </div>
    </div>
  );
}