import React from 'react';
import { Helmet } from 'react-helmet-async';
import CareerHero from '../components/careers/CareerHero';
import OpenPositions from '../components/careers/OpenPositions';
import CompanyBenefits from '../components/careers/CompanyBenefits';
import TeamCulture from '../components/careers/TeamCulture';
import NeuralBackground from '../components/NeuralBackground';

export default function Careers() {
  return (
    <>
      <Helmet>
        <title>Careers | TacticalSolutions.ai</title>
        <meta name="description" content="Join our team at TacticalSolutions.ai and help shape the future of AI-driven business automation." />
      </Helmet>
      <div className="relative min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 pt-24 pb-20">
        <div className="absolute inset-0">
          <NeuralBackground />
        </div>
        <div className="relative">
          <CareerHero />
          <OpenPositions />
          <CompanyBenefits />
          <TeamCulture />
        </div>
      </div>
    </>
  );
}