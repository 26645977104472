import React from 'react';

interface ProjectScopeProps {
  formData: {
    projectDescription: string;
    timeline: string;
    budget: string;
  };
  handleChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLSelectElement>) => void;
}

export default function ProjectScope({ formData, handleChange }: ProjectScopeProps) {
  return (
    <div className="glass-card p-6 rounded-xl backdrop-blur-md">
      <h2 className="text-2xl font-bold text-white mb-6">Project Scope</h2>
      <div className="space-y-6">
        <div>
          <label htmlFor="projectDescription" className="block text-sm font-medium text-gray-300 mb-1">
            Please describe what you're trying to build or automate:*
          </label>
          <textarea
            id="projectDescription"
            name="projectDescription"
            value={formData.projectDescription}
            onChange={handleChange}
            required
            rows={6}
            className="w-full px-4 py-2 rounded-md bg-gray-800 border border-gray-700 text-white focus:ring-2 focus:ring-teal-500 focus:border-transparent"
          />
        </div>
        <div>
          <label htmlFor="timeline" className="block text-sm font-medium text-gray-300 mb-1">
            What is your target timeline for this project?
          </label>
          <select
            id="timeline"
            name="timeline"
            value={formData.timeline}
            onChange={handleChange}
            className="w-full px-4 py-2 rounded-md bg-gray-800 border border-gray-700 text-white focus:ring-2 focus:ring-teal-500 focus:border-transparent"
          >
            <option value="">Select timeline</option>
            <option value="immediate">Immediate (1-3 months)</option>
            <option value="short">Short-term (3-6 months)</option>
            <option value="medium">Medium-term (6-12 months)</option>
            <option value="long">Long-term (12+ months)</option>
          </select>
        </div>
        <div>
          <label htmlFor="budget" className="block text-sm font-medium text-gray-300 mb-1">
            Estimated project budget range:
          </label>
          <select
            id="budget"
            name="budget"
            value={formData.budget}
            onChange={handleChange}
            className="w-full px-4 py-2 rounded-md bg-gray-800 border border-gray-700 text-white focus:ring-2 focus:ring-teal-500 focus:border-transparent"
          >
            <option value="">Select budget range</option>
            <option value="under1k">Under £1,000</option>
            <option value="1k-2.5k">£1,000 - £2,500</option>
            <option value="2.5k-5k">£2,500 - £5,000</option>
            <option value="5k-10k">£5,000 - £10,000</option>
            <option value="10k+">£10,000+</option>
            <option value="unsure">Not sure yet</option>
          </select>
        </div>
      </div>
    </div>
  );
}