import React from 'react';
import SEO from '../components/SEO';
import ContactHero from '../components/contact/ContactHero';
import ContactForm from '../components/contact/ContactForm';
import NeuralBackground from '../components/NeuralBackground';

export default function Contact() {
  return (
    <>
      <SEO 
        title="Contact Us"
        description="Get in touch with TacticalSolutions.ai for AI-driven business automation solutions. Let's discuss how we can transform your operations with intelligent automation."
        keywords={[
          'Contact AI Company',
          'AI Solutions',
          'Business Automation',
          'AI Consultation',
          'Technology Partnership',
          'Digital Transformation',
          'AI Implementation',
          'Business Innovation',
          'Process Automation',
          'Enterprise Solutions'
        ]}
      />
      <div className="relative min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 pt-32 pb-20">
        <div className="absolute inset-0">
          <NeuralBackground />
        </div>
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="flex flex-col items-center space-y-12">
            <ContactHero />
            <div className="w-full glass-card p-8 rounded-xl backdrop-blur-md">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}