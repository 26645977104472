import React from 'react';
import { Globe2, Brain, Coffee, Sparkles, Rocket, Users } from 'lucide-react';

const benefits = [
  {
    icon: Globe2,
    title: 'Work From Anywhere',
    description: 'True remote-first culture with team members worldwide'
  },
  {
    icon: Coffee,
    title: 'Flexible Schedule',
    description: 'Choose your own hours and work when you\'re most productive'
  },
  {
    icon: Brain,
    title: 'Learning & Growth',
    description: 'Access to online courses, conferences, and learning resources'
  },
  {
    icon: Sparkles,
    title: 'Project Ownership',
    description: 'Lead initiatives and make impactful decisions'
  },
  {
    icon: Users,
    title: 'Global Community',
    description: 'Connect with talented peers from around the world'
  },
  {
    icon: Rocket,
    title: 'Career Development',
    description: 'Clear progression paths and mentorship opportunities'
  }
];

export default function CompanyBenefits() {
  return (
    <section className="py-20 relative">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-bold text-center mb-12">
          <span className="text-gradient">Remote-First Benefits</span>
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {benefits.map((benefit, index) => (
            <div
              key={index}
              className="glass-card p-6 rounded-xl backdrop-blur-md transform hover:scale-105 transition-all duration-300"
            >
              <div className="flex items-center space-x-4">
                <div className="w-12 h-12 rounded-lg bg-gradient-to-br from-teal-500 to-purple-500 p-2.5 flex items-center justify-center">
                  <benefit.icon className="w-full h-full text-white" />
                </div>
                <div>
                  <h3 className="text-xl font-bold text-white mb-1">{benefit.title}</h3>
                  <p className="text-gray-300">{benefit.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}