import React from 'react';
import { CheckCircle } from 'lucide-react';

interface ActionableTipProps {
  tip: string;
  details: string;
}

export default function ActionableTip({ tip, details }: ActionableTipProps) {
  return (
    <div className="mb-6">
      <div className="flex items-start text-gray-300 mb-2">
        <CheckCircle className="h-5 w-5 text-teal-500 mr-3 flex-shrink-0 mt-0.5" />
        <span className="font-medium">{tip}</span>
      </div>
      <p className="text-sm text-gray-400 ml-8">
        {details}
      </p>
    </div>
  );
}