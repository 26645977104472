import type { CalculatorInputs, ROIResults } from './types';

const INDUSTRY_FACTORS = {
  general: 1,
  finance: 1.2,
  healthcare: 1.15,
  logistics: 1.1,
  manufacturing: 1.05,
  retail: 1.08
};

export function calculateROI(inputs: CalculatorInputs): ROIResults {
  const {
    employees,
    avgSalary,
    processTime,
    errorRate,
    industry
  } = inputs;

  // Calculate base metrics
  const hourlyRate = avgSalary / (52 * 40);
  const annualProcessHours = processTime * 52;
  const industryFactor = INDUSTRY_FACTORS[industry as keyof typeof INDUSTRY_FACTORS] || 1;

  // Calculate time savings (60-80% reduction in manual process time)
  const timeSavings = Math.round(annualProcessHours * 0.7 * industryFactor);

  // Calculate cost savings
  const laborSavings = timeSavings * hourlyRate;
  const errorCost = (avgSalary * errorRate * 0.01) * employees;
  const errorSavings = errorCost * 0.85; // 85% error reduction
  const costSavings = Math.round(laborSavings + errorSavings);

  // Calculate error reduction (80-95% reduction)
  const errorReduction = Math.round(85 * industryFactor);

  // Calculate ROI
  // Assuming implementation cost is roughly 4 months of potential savings
  const implementationCost = costSavings / 3;
  const roi = Math.round((costSavings - implementationCost) / implementationCost * 100);

  return {
    timeSavings,
    costSavings,
    errorReduction,
    roi
  };
}