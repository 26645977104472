import React, { useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import Logo from '../../common/Logo';
import NavLinks from './NavLinks';
import MobileMenu from './MobileMenu';
import { NavbarProps } from './types';
import { useScrollNavigation } from '../../../hooks/useScrollNavigation';

export default function Navbar({ scrollPosition }: NavbarProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isScrolled = scrollPosition > 50;
  const location = useLocation();
  const navigate = useNavigate();
  
  const { handleHomeClick, scrollToSection } = useScrollNavigation({
    location,
    navigate,
    setIsMenuOpen
  });

  const navbarClasses = `fixed w-full z-50 transition-all duration-300 ${
    isScrolled || isMenuOpen
      ? 'bg-white/95 backdrop-blur-md shadow-lg dark:bg-gray-900/95' 
      : 'bg-transparent'
  }`;

  const linkClasses = `transition-colors duration-300 ${
    isScrolled || isMenuOpen
      ? 'text-gray-900 hover:text-teal-500 dark:text-white dark:hover:text-teal-400'
      : 'text-white hover:text-teal-400'
  }`;

  return (
    <nav className={navbarClasses}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          <Link to="/" className="flex-shrink-0">
            <Logo theme={isScrolled || isMenuOpen ? 'dark' : 'light'} />
          </Link>
          
          <div className="hidden md:flex items-center space-x-8">
            <NavLinks
              linkClasses={linkClasses}
              handleHomeClick={handleHomeClick}
              scrollToSection={scrollToSection}
            />
          </div>

          <button 
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className={`md:hidden p-2 rounded-lg ${
              isScrolled || isMenuOpen ? 'text-gray-900 dark:text-white' : 'text-white'
            }`}
            aria-label="Toggle menu"
          >
            {isMenuOpen ? (
              <X className="h-6 w-6" />
            ) : (
              <Menu className="h-6 w-6" />
            )}
          </button>
        </div>

        <MobileMenu
          isMenuOpen={isMenuOpen}
          isScrolled={isScrolled}
          handleHomeClick={handleHomeClick}
          scrollToSection={scrollToSection}
          setIsMenuOpen={setIsMenuOpen}
        />
      </div>
    </nav>
  );
}