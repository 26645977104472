import React from 'react';
import { Linkedin, Calculator, Brain, BookOpen } from 'lucide-react';
import { Link } from 'react-router-dom';
import Logo from './Logo';

interface SocialLinkProps {
  href: string;
  icon: any;
}

function SocialLink({ href, icon: Icon }: SocialLinkProps) {
  return (
    <a
      href={href}
      className="text-gray-400 hover:text-teal-500 transition-colors"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon className="h-5 w-5" />
    </a>
  );
}

function FooterLinks({ links }: { links: { text: string; path: string; icon?: any }[] }) {
  return (
    <ul className="space-y-2">
      {links.map((link) => (
        <li key={link.text}>
          <Link 
            to={link.path} 
            className="text-gray-400 hover:text-teal-500 transition-colors inline-flex items-center"
          >
            {link.icon && <link.icon className="h-4 w-4 mr-2" />}
            {link.text}
          </Link>
        </li>
      ))}
    </ul>
  );
}

export default function Footer() {
  return (
    <footer className="relative bg-gray-900/90 backdrop-blur-md">
      <div className="relative">
        {/* Animated border container */}
        <div className="absolute inset-x-0 top-0 h-px overflow-hidden">
          {/* Main gradient line */}
          <div className="absolute inset-x-0 h-px bg-gradient-to-r from-transparent via-teal-500 to-transparent opacity-75" />
          
          {/* Animated pulse effect */}
          <div className="absolute inset-x-0 h-px">
            <div className="absolute inset-0 bg-gradient-to-r from-transparent via-teal-500 to-transparent animate-pulse-slow" />
          </div>
          
          {/* Moving highlight */}
          <div className="absolute inset-y-0 w-1/4 bg-gradient-to-r from-transparent via-teal-400 to-transparent animate-wave" 
               style={{ 
                 filter: 'blur(4px)',
                 animation: 'wave 3s linear infinite'
               }} 
          />
          
          {/* Frequency bars */}
          <div className="absolute inset-0 flex justify-around">
            {[...Array(50)].map((_, i) => (
              <div
                key={i}
                className="w-px h-full bg-teal-500/30"
                style={{
                  animation: `frequencyBar ${0.5 + Math.random() * 1}s ease-in-out infinite`,
                  animationDelay: `${Math.random() * 2}s`
                }}
              />
            ))}
          </div>
        </div>

        {/* Footer content */}
        <div className="relative pt-2">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
              <div className="col-span-1 md:col-span-2">
                <div className="flex items-center mb-4">
                  <Logo size="sm" />
                </div>
                <p className="text-gray-400 mb-4">
                  Empowering enterprises with intelligent solutions for the digital age.
                </p>
                <div className="flex space-x-4">
                  <SocialLink href="https://www.linkedin.com/company/tacticalsolutions-ai" icon={Linkedin} />
                </div>
              </div>

              <div>
                <h3 className="text-white font-semibold mb-4">Company</h3>
                <FooterLinks
                  links={[
                    { text: 'About Us', path: '/about' },
                    { text: 'Work For Us', path: '/careers' },
                    { text: 'Contact', path: '/contact' },
                    { text: 'AI Technology Explained', path: '/ai-explainers', icon: BookOpen },
                    { text: 'ROI Calculator', path: '/roi-calculator', icon: Calculator },
                    { text: 'AI Readiness Check', path: '/ai-readiness', icon: Brain }
                  ]}
                />
              </div>

              <div>
                <h3 className="text-white font-semibold mb-4">Legal</h3>
                <FooterLinks
                  links={[
                    { text: 'Privacy Policy', path: '/privacy-policy' },
                    { text: 'Terms of Service', path: '/terms-of-service' },
                    { text: 'Ethics Policy', path: '/ethics-policy' }
                  ]}
                />
              </div>
            </div>

            <div className="mt-12 pt-8 border-t border-gray-800">
              <div className="flex flex-col md:flex-row justify-between items-center">
                <p className="text-gray-400 text-sm">
                  © 2024 TacticalSolutions.ai. All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}