import React from 'react';
import { ArrowRight, AlertTriangle, CheckCircle, Clock, Calculator } from 'lucide-react';
import { Link } from 'react-router-dom';
import ActionableTip from './tips/ActionableTip';
import { getActionableTips } from './tips/getTips';

interface ResultsCardProps {
  score: number;
  onRequestConsultation: () => void;
}

export default function ResultsCard({ score, onRequestConsultation }: ResultsCardProps) {
  const getReadinessLevel = () => {
    if (score >= 80) return { level: 'Optimization Ready', icon: CheckCircle };
    if (score >= 60) return { level: 'Automation Ready', icon: Clock };
    return { level: 'Immediate Action Needed', icon: AlertTriangle };
  };

  const getRecommendations = () => {
    if (score >= 80) {
      return {
        message: "Your organization shows strong potential for advanced AI automation. Our solutions can help you:",
        points: [
          'Optimize existing processes for maximum efficiency',
          'Integrate AI for predictive insights',
          'Scale automation across departments',
          'Achieve significant cost savings'
        ]
      };
    }
    if (score >= 60) {
      return {
        message: "Your organization is well-positioned to benefit from AI automation. We can help you:",
        points: [
          'Automate key business processes',
          'Reduce manual workload by 60-80%',
          'Minimize errors and inconsistencies',
          'Improve operational efficiency'
        ]
      };
    }
    return {
      message: "Your organization has significant opportunity for improvement through AI automation:",
      points: [
        'Eliminate manual data entry and processing',
        'Reduce errors by up to 95%',
        'Free up staff for valuable tasks',
        'See ROI within months'
      ]
    };
  };

  const { level, icon: Icon } = getReadinessLevel();
  const recommendations = getRecommendations();
  const actionableTips = getActionableTips(score);

  return (
    <div className="max-w-4xl mx-auto">
      <div className="glass-card p-8 rounded-xl backdrop-blur-md mb-8">
        <div className="text-center mb-8">
          <div className="inline-flex items-center justify-center mb-4">
            <Icon className="h-12 w-12 text-teal-500" />
          </div>
          <h3 className="text-2xl font-bold text-white mb-2">{level}</h3>
          <div className="relative inline-flex items-center justify-center mt-4">
            <svg className="w-32 h-32">
              <circle
                className="text-gray-700"
                strokeWidth="8"
                stroke="currentColor"
                fill="transparent"
                r="56"
                cx="64"
                cy="64"
              />
              <circle
                className="text-teal-500"
                strokeWidth="8"
                strokeLinecap="round"
                stroke="currentColor"
                fill="transparent"
                r="56"
                cx="64"
                cy="64"
                strokeDasharray={`${score * 3.51} 351`}
                transform="rotate(-90 64 64)"
              />
            </svg>
            <span className="absolute text-3xl font-bold text-white">{score}%</span>
          </div>
        </div>

        <div className="border-t border-gray-700 pt-8">
          <h4 className="text-xl font-bold text-white mb-6">Actionable Tips to Improve Your Readiness</h4>
          <div className="space-y-2">
            {actionableTips.map((tip, index) => (
              <ActionableTip key={index} tip={tip.tip} details={tip.details} />
            ))}
          </div>
        </div>

        <div className="border-t border-gray-700 pt-8 mt-8">
          <div className="space-y-6">
            <p className="text-gray-300 text-lg">{recommendations.message}</p>
            <ul className="space-y-4">
              {recommendations.points.map((point, index) => (
                <li key={index} className="flex items-center text-gray-300">
                  <div className="w-2 h-2 rounded-full bg-teal-500 mr-3" />
                  {point}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="border-t border-gray-700 pt-8 mt-8">
          <div className="flex flex-col sm:flex-row gap-4">
            <Link
              to="/roi-calculator"
              className="flex-1 px-8 py-4 text-lg font-medium rounded-md text-white bg-gradient-to-r from-purple-500 to-purple-600 hover:from-purple-600 hover:to-purple-700 transition-all duration-200 flex items-center justify-center"
            >
              <Calculator className="h-5 w-5 mr-2" />
              <span>Calculate Your ROI</span>
            </Link>

            <button
              onClick={onRequestConsultation}
              className="flex-1 px-8 py-4 text-lg font-medium rounded-md text-white bg-gradient-to-r from-teal-500 to-teal-600 hover:from-teal-600 hover:to-teal-700 transition-all duration-200 flex items-center justify-center"
            >
              <span>Get Your Free Consultation</span>
              <ArrowRight className="ml-2 h-5 w-5" />
            </button>
          </div>

          <p className="text-sm text-gray-400 text-center mt-4">
            Discover how much time and money you could save with AI automation
          </p>
        </div>
      </div>
    </div>
  );
}