import React, { useState } from 'react';
import { ArrowRight, ArrowLeft, Sparkles, TrendingUp, Building2, Shield } from 'lucide-react';

const aiTransformations = [
  {
    title: 'Enterprise AI Transformation',
    subtitle: 'Digital Evolution',
    description: 'Revolutionizing operations through AI-driven automation and intelligent decision-making systems.',
    image: 'https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?auto=format&fit=crop&w=2000&q=80',
    metrics: [
      { label: 'Efficiency Gain', value: '300%', trend: '+200%' },
      { label: 'Cost Reduction', value: '60%', trend: '+45%' },
      { label: 'Decision Speed', value: '0.5s', trend: '+800%' }
    ],
    tags: ['Enterprise AI', 'Process Automation', 'Smart Analytics']
  },
  {
    title: 'Intelligent Operations',
    subtitle: 'Business Innovation',
    description: 'Next-generation operational excellence through AI-powered automation and predictive analytics.',
    image: 'https://images.unsplash.com/photo-1552664730-d307ca884978?auto=format&fit=crop&w=2000&q=80',
    metrics: [
      { label: 'Productivity', value: '90%', trend: '+65%' },
      { label: 'Response Time', value: '1.2s', trend: '+950%' },
      { label: 'Accuracy', value: '98%', trend: '+23%' }
    ],
    tags: ['Operational AI', 'Process Mining', 'Predictive Analytics']
  },
  {
    title: 'Strategic Intelligence',
    subtitle: 'Business Insights',
    description: 'AI-driven market analysis and strategic planning achieving unprecedented accuracy and insights.',
    image: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&w=2000&q=80',
    metrics: [
      { label: 'Accuracy', value: '92%', trend: '+40%' },
      { label: 'Data Sources', value: '500+', trend: '+300%' },
      { label: 'Processing', value: '5TB/s', trend: '+1200%' }
    ],
    tags: ['Strategic AI', 'Market Intelligence', 'Predictive Planning']
  }
];

export default function CaseStudies() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleNext = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setActiveIndex((prev) => (prev + 1) % aiTransformations.length);
    setTimeout(() => setIsTransitioning(false), 500);
  };

  const handlePrev = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setActiveIndex((prev) => (prev - 1 + aiTransformations.length) % aiTransformations.length);
    setTimeout(() => setIsTransitioning(false), 500);
  };

  return (
    <section className="relative py-32 bg-gray-900 overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900" />
      <div className="absolute inset-0 bg-grid opacity-[0.02]" />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <div className="text-center mb-20">
          <h2 className="text-5xl font-bold mb-6">
            <span className="text-gradient">AI Impact Showcase</span>
          </h2>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto">
            Transforming businesses through innovative AI solutions
          </p>
        </div>

        <div className="relative">
          <div className="grid lg:grid-cols-2 gap-12 items-center">
            <div className="relative group perspective-container">
              <div className="absolute -inset-1 bg-gradient-to-r from-teal-500 to-purple-500 rounded-2xl blur opacity-20 group-hover:opacity-30 transition-opacity duration-500" />
              <div className="relative h-[400px] rounded-2xl overflow-hidden card-3d">
                <img
                  src={aiTransformations[activeIndex].image}
                  alt={aiTransformations[activeIndex].title}
                  className="w-full h-full object-cover transform scale-105 group-hover:scale-110 transition-transform duration-700"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/40 to-transparent" />
                <div className="card-shadow" />
              </div>
            </div>

            <div className="relative space-y-8">
              <div className="space-y-4">
                <h3 className="text-4xl font-bold text-white">
                  {aiTransformations[activeIndex].title}
                </h3>
                <p className="text-xl text-teal-400">
                  {aiTransformations[activeIndex].subtitle}
                </p>
                <p className="text-gray-300 text-lg">
                  {aiTransformations[activeIndex].description}
                </p>
              </div>

              <div className="grid grid-cols-3 gap-6">
                {aiTransformations[activeIndex].metrics.map((metric, index) => (
                  <div
                    key={index}
                    className="glass-card p-4 rounded-xl backdrop-blur-sm transform hover:scale-105 transition-transform duration-300"
                  >
                    <div className="text-3xl font-bold text-white mb-1">
                      {metric.value}
                    </div>
                    <div className="text-sm text-gray-400">
                      {metric.label}
                    </div>
                    <div className="text-sm text-green-400">
                      {metric.trend}
                    </div>
                  </div>
                ))}
              </div>

              <div className="flex flex-wrap gap-2">
                {aiTransformations[activeIndex].tags.map((tag, index) => (
                  <span
                    key={index}
                    className="px-3 py-1 rounded-full text-sm bg-teal-500/10 text-teal-400 border border-teal-500/20"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          </div>

          <div className="absolute top-1/2 -translate-y-1/2 left-0 right-0 flex justify-between pointer-events-none">
            <button
              onClick={handlePrev}
              className="transform -translate-x-1/2 w-12 h-12 rounded-full bg-white/10 backdrop-blur-sm flex items-center justify-center text-white hover:bg-white/20 transition-all pointer-events-auto"
            >
              <ArrowLeft className="w-5 h-5" />
            </button>
            <button
              onClick={handleNext}
              className="transform translate-x-1/2 w-12 h-12 rounded-full bg-white/10 backdrop-blur-sm flex items-center justify-center text-white hover:bg-white/20 transition-all pointer-events-auto"
            >
              <ArrowRight className="w-5 h-5" />
            </button>
          </div>

          <div className="flex justify-center mt-8 gap-2">
            {aiTransformations.map((_, index) => (
              <button
                key={index}
                onClick={() => setActiveIndex(index)}
                className={`w-12 h-1 rounded-full transition-all duration-300 ${
                  index === activeIndex
                    ? 'bg-teal-500'
                    : 'bg-gray-700 hover:bg-gray-600'
                }`}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}