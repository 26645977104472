import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ClipboardCheck, Network, Lightbulb, Wrench, Beaker, Rocket, RefreshCw, ArrowRight } from 'lucide-react';

const processSteps = [
  {
    icon: ClipboardCheck,
    number: '01',
    title: 'AI Readiness Assessment',
    description: 'Evaluating your infrastructure, data quality, and organizational readiness for AI integration.',
    details: [
      'Technical Infrastructure Review',
      'Data Quality Analysis',
      'Team Capability Assessment',
      'Integration Requirements'
    ]
  },
  {
    icon: Network,
    number: '02',
    title: 'Business Process Mapping',
    description: 'Creating detailed maps of workflows to identify optimization opportunities.',
    details: [
      'Workflow Documentation',
      'Bottleneck Analysis',
      'Efficiency Metrics',
      'Process Dependencies'
    ]
  },
  {
    icon: Lightbulb,
    number: '03',
    title: 'AI Opportunity Identification',
    description: 'Pinpointing areas where AI can drive significant improvements.',
    details: [
      'Impact Assessment',
      'ROI Calculation',
      'Priority Matrix',
      'Solution Scoping'
    ]
  },
  {
    icon: Wrench,
    number: '04',
    title: 'Customized Solution Design',
    description: 'Designing no-code/low-code AI solutions tailored to your needs.',
    details: [
      'Architecture Planning',
      'Integration Design',
      'Security Framework',
      'Scalability Planning'
    ]
  },
  {
    icon: Beaker,
    number: '05',
    title: 'Pilot Implementation',
    description: 'Rolling out small-scale implementation for validation and refinement.',
    details: [
      'Controlled Testing',
      'Performance Monitoring',
      'User Feedback',
      'Iterative Refinement'
    ]
  },
  {
    icon: Rocket,
    number: '06',
    title: 'Full-Scale Deployment',
    description: 'Implementing AI solutions across your organization with comprehensive training.',
    details: [
      'Phased Rollout',
      'Team Training',
      'Documentation',
      'Support System'
    ]
  },
  {
    icon: RefreshCw,
    number: '07',
    title: 'Continuous Optimization',
    description: 'Monitoring and improving solution performance for long-term success.',
    details: [
      'Performance Analytics',
      'Regular Updates',
      'Optimization Cycles',
      'Growth Planning'
    ]
  }
];

export default function WhatWeDo() {
  const [activeStep, setActiveStep] = useState(0);

  return (
    <section id="process" className="py-32 bg-gray-900 relative overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900" />
      <div className="absolute inset-0 bg-grid opacity-[0.02]" />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <div className="text-center mb-20">
          <h2 className="text-5xl font-bold mb-6">
            <span className="text-gradient">Our Process</span>
          </h2>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto">
            A systematic approach to transforming your business through AI innovation
          </p>
        </div>

        <div className="grid lg:grid-cols-2 gap-12 items-start mb-20">
          {/* Process Steps Navigation */}
          <div className="space-y-4">
            {processSteps.map((step, index) => (
              <button
                key={index}
                onClick={() => setActiveStep(index)}
                className={`w-full text-left p-6 rounded-xl transition-all duration-300 ${
                  activeStep === index
                    ? 'bg-white/10 backdrop-blur-sm transform translate-x-2'
                    : 'hover:bg-white/5'
                }`}
              >
                <div className="flex items-center">
                  <div className={`w-12 h-12 rounded-lg flex items-center justify-center transition-colors duration-300 ${
                    activeStep === index ? 'bg-teal-500' : 'bg-gray-800'
                  }`}>
                    <step.icon className={`w-6 h-6 ${
                      activeStep === index ? 'text-white' : 'text-gray-400'
                    }`} />
                  </div>
                  <div className="ml-4 flex-1">
                    <div className="flex items-center justify-between">
                      <span className={`text-sm font-mono ${
                        activeStep === index ? 'text-teal-400' : 'text-gray-500'
                      }`}>
                        {step.number}
                      </span>
                      {activeStep === index && (
                        <ArrowRight className="w-5 h-5 text-teal-400" />
                      )}
                    </div>
                    <h3 className={`text-lg font-semibold ${
                      activeStep === index ? 'text-white' : 'text-gray-400'
                    }`}>
                      {step.title}
                    </h3>
                  </div>
                </div>
              </button>
            ))}
          </div>

          {/* Step Details */}
          <div className="relative">
            <div className="sticky top-24">
              <div className="bg-white/10 backdrop-blur-sm rounded-xl p-8 transform transition-all duration-500">
                <div className="mb-8">
                  <span className="text-sm font-mono text-teal-400">
                    {processSteps[activeStep].number}
                  </span>
                  <h3 className="text-3xl font-bold text-white mt-2 mb-4">
                    {processSteps[activeStep].title}
                  </h3>
                  <p className="text-gray-300 text-lg">
                    {processSteps[activeStep].description}
                  </p>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  {processSteps[activeStep].details.map((detail, index) => (
                    <div
                      key={index}
                      className="flex items-center space-x-2 text-gray-300"
                    >
                      <div className="w-1.5 h-1.5 rounded-full bg-teal-500" />
                      <span>{detail}</span>
                    </div>
                  ))}
                </div>

                {/* Decorative Elements */}
                <div className="absolute -top-4 -right-4 w-64 h-64 bg-teal-500/10 rounded-full blur-3xl" />
                <div className="absolute -bottom-4 -left-4 w-64 h-64 bg-purple-500/10 rounded-full blur-3xl" />
              </div>
            </div>
          </div>
        </div>

        <div className="text-center">
          <Link 
            to="/contact" 
            className="inline-flex items-center px-8 py-4 text-lg font-medium rounded-md text-white hover:text-white bg-gradient-to-r from-teal-500 to-teal-600 hover:from-teal-600 hover:to-teal-700 transition-all duration-200 shadow-lg hover:shadow-xl transform hover:-translate-y-0.5"
          >
            <span>Start Your AI Journey Today</span>
            <ArrowRight className="ml-2 h-5 w-5 transition-transform group-hover:translate-x-1" />
          </Link>
        </div>
      </div>
    </section>
  );
}