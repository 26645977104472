import React from 'react';
import { Link } from 'react-router-dom';
import { Shield, Zap, Target, BarChart, ArrowRight, Calculator } from 'lucide-react';

const features = [
  {
    icon: Shield,
    title: 'Process Intelligence',
    description: 'Transform your operations with AI that understands your business workflows.',
    features: [
      'Visual Process Mapping',
      'Automatic Bottleneck Detection',
      'Real-time Performance Insights',
      'ROI Forecasting'
    ],
    gradient: 'from-teal-500/20 to-blue-500/20'
  },
  {
    icon: Zap,
    title: 'Intelligent Document Processing',
    description: 'Turn any document into actionable data with advanced AI processing.',
    features: [
      'Universal Document Understanding',
      'Smart Data Extraction',
      'Self-Learning Templates',
      'Automated Validation'
    ],
    gradient: 'from-blue-500/20 to-purple-500/20'
  },
  {
    icon: Target,
    title: 'Business Intelligence',
    description: 'Make confident decisions with AI-powered insights from your existing data.',
    features: [
      'Natural Language Analytics',
      'Predictive Insights',
      'Automated Reporting',
      'Smart Alerts'
    ],
    gradient: 'from-purple-500/20 to-pink-500/20'
  },
  {
    icon: BarChart,
    title: 'AI-Powered Customer Service',
    description: 'Enhance your existing customer service platforms with intelligent automation.',
    features: [
      'Intelligent Routing',
      'Multi-Platform Support',
      'Response Automation',
      'Seamless Integration'
    ],
    gradient: 'from-pink-500/20 to-teal-500/20'
  }
];

export default function Features() {
  return (
    <section className="py-32 bg-white relative overflow-hidden">
      <div className="absolute inset-0 bg-grid opacity-[0.02]" />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <div className="text-center mb-20">
          <h2 className="text-5xl font-bold mb-6">
            <span className="text-gradient">Advanced Capabilities</span>
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Enhance your existing systems with powerful AI capabilities
          </p>
        </div>

        <div className="text-center mb-12">
          <Link 
            to="/roi-calculator" 
            className="inline-flex items-center px-8 py-4 text-lg font-medium rounded-md text-white bg-gradient-to-r from-purple-500 to-purple-600 hover:from-purple-600 hover:to-purple-700 transition-all duration-200 shadow-lg hover:shadow-xl transform hover:-translate-y-0.5"
          >
            <Calculator className="mr-2 h-5 w-5" />
            <span>Calculate Your ROI</span>
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className="group relative flex"
            >
              <div className="relative bg-white rounded-xl transition-all duration-500 transform-gpu hover:shadow-2xl hover:shadow-teal-500/10 border border-gray-100 flex flex-col w-full">
                <div className="magnetic-glow" />
                
                <div className="relative z-10 flex flex-col h-full p-8">
                  {/* Icon Container */}
                  <div className="mb-6">
                    <div className={`
                      w-16 h-16 rounded-xl
                      bg-gradient-to-br ${feature.gradient}
                      flex items-center justify-center
                      group-hover:scale-110 transition-transform duration-500
                    `}>
                      <feature.icon className="h-8 w-8 text-gray-900" />
                    </div>
                  </div>

                  {/* Title Container */}
                  <h3 className="text-2xl font-bold text-gray-900 group-hover:text-teal-600 leading-tight mb-4">
                    {feature.title}
                  </h3>
                  
                  {/* Description Container */}
                  <p className="text-gray-600 text-lg leading-snug mb-8">
                    {feature.description}
                  </p>

                  {/* Features Container */}
                  <div className="mt-auto space-y-3">
                    {feature.features.map((feat, i) => (
                      <div key={i} className="flex items-center text-sm text-teal-600">
                        <div className="w-1.5 h-1.5 rounded-full bg-teal-500 mr-2" />
                        {feat}
                      </div>
                    ))}
                  </div>
                </div>

                <div className="card-shadow" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}