import React from 'react';
import { Heart, Brain, Lightbulb, Users } from 'lucide-react';

const values = [
  {
    icon: Heart,
    title: 'Personal Understanding',
    description: 'Our solutions are born from real experiences and challenges faced as business owners and parents.'
  },
  {
    icon: Brain,
    title: 'Technical Excellence',
    description: 'Combining deep technical expertise with practical business acumen for effective solutions.'
  },
  {
    icon: Users,
    title: 'Human-First Approach',
    description: 'Technology that adapts to people, not the way around, making business management more natural.'
  },
  {
    icon: Lightbulb,
    title: 'Innovative Solutions',
    description: 'Creative problem-solving that turns daily challenges into opportunities for automation and growth.'
  }
];

export default function FounderStory() {
  return (
    <section className="relative py-12">
      <div className="absolute inset-0 bg-gradient-to-b from-gray-900/90 via-gray-900/85 to-gray-900/90" />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <div className="space-y-8">
          {/* Story Section with Logo */}
          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <div className="grid md:grid-cols-3 gap-8">
              <div className="md:col-span-2">
                <h2 className="text-4xl font-bold mb-6">
                  <span className="text-gradient">Our Story</span>
                </h2>
                <div className="prose prose-lg prose-invert max-w-none">
                  <div className="space-y-6 text-lg text-gray-300">
                    <p className="leading-relaxed">
                      At TacticalSolutions.ai, our story is deeply rooted in real-world experience and personal transformation. Our founder, Anthony Keen, began his entrepreneurial journey at just 17 years old, demonstrating early business acumen by transforming a modest £4,000 investment in construction into a multimillion-pound enterprise.
                    </p>
                    <blockquote className="border-l-4 border-teal-500 pl-4 my-6 italic text-gray-200">
                      "Sometimes the biggest challenges in life lead to the most innovative solutions. For me, it was about finding a way to be both a present father and an effective business owner."
                    </blockquote>
                    <p className="leading-relaxed">
                      However, life had different plans. During the COVID pandemic, Anthony faced a significant transition when he became a full-time single father. This pivotal moment brought unexpected challenges, particularly in managing business operations while prioritizing family responsibilities.
                    </p>
                  </div>
                </div>
              </div>
              
              {/* Logo Frame */}
              <div className="flex items-center justify-center">
                <img
                  src="https://stackblitz.com/storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBN0toRmc9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--8ed48ca1e1f8032e164818e9164ed73696e6ab53/Copy%20of%20HS%20Alwafa%20digital.png"
                  alt="TacticalSolutions.ai Logo"
                  className="w-48 h-auto object-contain"
                />
              </div>
            </div>
          </div>

          {/* Values Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {values.map((value, index) => (
              <div
                key={index}
                className="glass-card p-6 rounded-xl backdrop-blur-md transform hover:scale-105 transition-all duration-300"
              >
                <div className="flex items-center space-x-4 mb-4">
                  <div className="w-12 h-12 rounded-lg bg-gradient-to-br from-teal-500 to-purple-500 p-2.5 flex items-center justify-center">
                    <value.icon className="w-full h-full text-white" />
                  </div>
                  <h3 className="text-xl font-bold text-white">{value.title}</h3>
                </div>
                <p className="text-gray-300">{value.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}