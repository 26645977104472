import React from 'react';
import SEO from '../components/SEO';
import { Link } from 'react-router-dom';
import { ArrowRight, BookOpen } from 'lucide-react';
import DigitalRainBackground from '../components/DigitalRainBackground';
import AboutHero from '../components/about/AboutHero';
import FounderStory from '../components/about/FounderStory';
import BirthOfSolution from '../components/about/BirthOfSolution';
import MissionSection from '../components/about/MissionSection';

export default function About() {
  return (
    <>
      <SEO 
        title="About Us"
        description="Learn about TacticalSolutions.ai's journey in revolutionizing business automation through AI innovation. Discover our mission, values, and commitment to accessible AI solutions."
        keywords={[
          'AI Company',
          'Business Innovation',
          'AI Technology',
          'Digital Transformation',
          'AI Solutions Provider',
          'Technology Innovation',
          'Business Automation',
          'AI Expertise',
          'Enterprise Solutions',
          'Digital Innovation'
        ]}
      />
      <div className="relative min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900">
        <DigitalRainBackground />
        <AboutHero />
        <FounderStory />
        
        <div className="relative py-20">
          <div className="absolute inset-0 bg-gradient-to-r from-teal-500/10 to-purple-500/10" />
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="glass-card p-12 rounded-xl backdrop-blur-md text-center">
              <h2 className="text-4xl font-bold mb-6">
                <span className="text-gradient">Understanding AI Technology</span>
              </h2>
              <p className="text-xl text-gray-300 mb-8 max-w-3xl mx-auto">
                Explore our comprehensive guide to AI technology, where we break down complex concepts into clear, actionable insights for your business.
              </p>
              <Link 
                to="/ai-explainers"
                className="inline-flex items-center px-12 py-4 text-lg font-medium rounded-md text-white bg-gradient-to-r from-teal-500 to-teal-600 hover:from-teal-600 hover:to-teal-700 transition-all duration-200 shadow-lg hover:shadow-xl transform hover:-translate-y-0.5"
              >
                <BookOpen className="h-5 w-5 mr-2" />
                <span>Explore AI Technology</span>
                <ArrowRight className="ml-2 h-5 w-5" />
              </Link>
            </div>
          </div>
        </div>

        <BirthOfSolution />
        <MissionSection />
      </div>
    </>
  );
}