import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const explanations = [
  {
    question: 'How does Machine Learning work?',
    answer: 'Machine Learning works by analyzing patterns in data to make predictions or decisions. Instead of following explicit programming rules, ML systems learn from examples and improve their performance over time through experience.',
    details: [
      'Data Collection & Processing',
      'Pattern Recognition',
      'Model Training',
      'Continuous Improvement'
    ]
  },
  {
    question: 'What is the difference between AI and Machine Learning?',
    answer: 'AI is the broader concept of machines being able to carry out tasks intelligently, while Machine Learning is a specific subset of AI that focuses on the ability of machines to learn from data without being explicitly programmed.',
    details: [
      'AI encompasses all intelligent behavior',
      'ML is a method to achieve AI',
      'AI can include rule-based systems',
      'ML requires data to learn'
    ]
  },
  {
    question: 'How can businesses implement AI effectively?',
    answer: 'Effective AI implementation requires a strategic approach that includes identifying specific business problems, ensuring data quality, choosing appropriate solutions, and managing change within the organization.',
    details: [
      'Problem Identification',
      'Data Preparation',
      'Solution Selection',
      'Implementation & Training'
    ]
  },
  {
    question: 'What are the limitations of current AI technology?',
    answer: 'While powerful, AI has limitations including the need for quality data, potential bias in training data, limited contextual understanding, and the requirement for human oversight in critical decisions.',
    details: [
      'Data Dependencies',
      'Contextual Understanding',
      'Ethical Considerations',
      'Human Oversight Needs'
    ]
  }
];

export default function DetailedExplanations() {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  return (
    <section className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
      <h2 className="text-4xl font-bold text-center mb-12">
        <span className="text-gradient">Detailed Explanations</span>
      </h2>

      <div className="space-y-4">
        {explanations.map((item, index) => (
          <div
            key={index}
            className="glass-card rounded-xl backdrop-blur-md overflow-hidden"
          >
            <button
              onClick={() => setOpenIndex(openIndex === index ? null : index)}
              className="w-full p-6 text-left flex items-center justify-between hover:bg-white/5 transition-colors"
            >
              <h3 className="text-xl font-bold text-white">{item.question}</h3>
              {openIndex === index ? (
                <ChevronUp className="h-6 w-6 text-teal-400" />
              ) : (
                <ChevronDown className="h-6 w-6 text-teal-400" />
              )}
            </button>

            {openIndex === index && (
              <div className="px-6 pb-6">
                <p className="text-gray-300 mb-4">{item.answer}</p>
                <div className="grid grid-cols-2 gap-4">
                  {item.details.map((detail, i) => (
                    <div key={i} className="flex items-center text-sm text-teal-400">
                      <div className="w-1.5 h-1.5 rounded-full bg-teal-500 mr-2" />
                      {detail}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
}