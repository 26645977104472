import React from 'react';
import { Link } from 'react-router-dom';
import { NavLinkProps } from './types';
import { Brain } from 'lucide-react';

export default function NavLinks({ 
  linkClasses, 
  handleHomeClick, 
  scrollToSection, 
  setIsMenuOpen 
}: NavLinkProps) {
  return (
    <>
      <button
        onClick={handleHomeClick}
        className={linkClasses}
      >
        Home
      </button>
      <button
        onClick={() => scrollToSection('process')}
        className={linkClasses}
      >
        Our Process
      </button>
      <Link 
        to="/about"
        className={linkClasses}
        onClick={() => setIsMenuOpen?.(false)}
      >
        About
      </Link>
      <Link 
        to="/careers"
        className={linkClasses}
        onClick={() => setIsMenuOpen?.(false)}
      >
        Work For Us
      </Link>
      <Link 
        to="/contact"
        className={linkClasses}
        onClick={() => setIsMenuOpen?.(false)}
      >
        Contact
      </Link>
      <Link 
        to="/ai-readiness"
        className="inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-white bg-gradient-to-r from-teal-500 to-teal-600 hover:from-teal-600 hover:to-teal-700 transition-all duration-200"
        onClick={() => setIsMenuOpen?.(false)}
      >
        <Brain className="h-4 w-4 mr-2" />
        AI Readiness Check
      </Link>
    </>
  );
}