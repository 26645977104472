import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';

export default function BuckyballBackground() {
  const containerRef = useRef<HTMLDivElement>(null);
  const sceneRef = useRef<THREE.Scene | null>(null);
  const cameraRef = useRef<THREE.PerspectiveCamera | null>(null);
  const rendererRef = useRef<THREE.WebGLRenderer | null>(null);
  const meshRef = useRef<THREE.Group | null>(null);
  const glowMeshRef = useRef<THREE.Group | null>(null);
  const particlesRef = useRef<THREE.Points | null>(null);
  const energyFieldRef = useRef<THREE.Points | null>(null);
  const frameIdRef = useRef<number>(0);
  const mousePosition = useRef({ x: 0, y: 0 });

  useEffect(() => {
    if (!containerRef.current) return;

    // Enhanced scene setup with fog for depth
    const scene = new THREE.Scene();
    scene.fog = new THREE.FogExp2(0x000000, 0.05);
    sceneRef.current = scene;

    // Cinematic camera setup
    const camera = new THREE.PerspectiveCamera(
      70,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    camera.position.z = 7;
    cameraRef.current = camera;

    // High-quality renderer setup
    const renderer = new THREE.WebGLRenderer({ 
      alpha: true,
      antialias: true,
      powerPreference: "high-performance",
      stencil: false,
      depth: true
    });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x000000, 0);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 1.2;
    containerRef.current.appendChild(renderer.domElement);
    rendererRef.current = renderer;

    // Create main geometry group for complex structure
    const mainGroup = new THREE.Group();
    const glowGroup = new THREE.Group();

    // Multiple layers of icosahedrons with different sizes and rotations
    const createLayer = (radius: number, detail: number, color: number, opacity: number) => {
      const geometry = new THREE.IcosahedronGeometry(radius, detail);
      const material = new THREE.MeshBasicMaterial({
        color,
        wireframe: true,
        transparent: true,
        opacity,
        blending: THREE.AdditiveBlending
      });
      return new THREE.Mesh(geometry, material);
    };

    // Create multiple layers with different properties
    const layers = [
      createLayer(2.5, 2, 0x00a8cc, 0.3),
      createLayer(2.55, 2, 0x00ffff, 0.1),
      createLayer(2.45, 2, 0x0088ff, 0.2),
      createLayer(2.4, 1, 0x00ddff, 0.15)
    ];

    layers.forEach(layer => {
      mainGroup.add(layer);
      const glowLayer = layer.clone();
      glowLayer.scale.multiplyScalar(1.02);
      glowGroup.add(glowLayer);
    });

    // Energy field particles
    const energyFieldGeometry = new THREE.BufferGeometry();
    const energyFieldCount = 2000;
    const energyFieldPositions = new Float32Array(energyFieldCount * 3);
    const energyFieldSizes = new Float32Array(energyFieldCount);
    const energyFieldColors = new Float32Array(energyFieldCount * 3);

    for (let i = 0; i < energyFieldCount; i++) {
      const theta = Math.random() * Math.PI * 2;
      const phi = Math.acos(Math.random() * 2 - 1);
      const radius = 2.5 + Math.random() * 2;

      energyFieldPositions[i * 3] = radius * Math.sin(phi) * Math.cos(theta);
      energyFieldPositions[i * 3 + 1] = radius * Math.sin(phi) * Math.sin(theta);
      energyFieldPositions[i * 3 + 2] = radius * Math.cos(phi);

      energyFieldSizes[i] = Math.random() * 2;

      // Create color gradient from teal to blue
      const color = new THREE.Color();
      color.setHSL(0.5 + Math.random() * 0.1, 0.8, 0.6);
      energyFieldColors[i * 3] = color.r;
      energyFieldColors[i * 3 + 1] = color.g;
      energyFieldColors[i * 3 + 2] = color.b;
    }

    energyFieldGeometry.setAttribute('position', new THREE.BufferAttribute(energyFieldPositions, 3));
    energyFieldGeometry.setAttribute('size', new THREE.BufferAttribute(energyFieldSizes, 1));
    energyFieldGeometry.setAttribute('color', new THREE.BufferAttribute(energyFieldColors, 3));

    const energyFieldMaterial = new THREE.PointsMaterial({
      size: 0.05,
      transparent: true,
      opacity: 0.6,
      vertexColors: true,
      blending: THREE.AdditiveBlending,
      sizeAttenuation: true
    });

    const energyField = new THREE.Points(energyFieldGeometry, energyFieldMaterial);
    energyFieldRef.current = energyField;
    scene.add(energyField);

    // Ambient particle system
    const particlesGeometry = new THREE.BufferGeometry();
    const particleCount = 1500;
    const positions = new Float32Array(particleCount * 3);
    const particleSizes = new Float32Array(particleCount);
    const colors = new Float32Array(particleCount * 3);

    for (let i = 0; i < particleCount; i++) {
      const distance = Math.random() * 10;
      const theta = Math.random() * Math.PI * 2;
      const phi = Math.acos(Math.random() * 2 - 1);

      positions[i * 3] = distance * Math.sin(phi) * Math.cos(theta);
      positions[i * 3 + 1] = distance * Math.sin(phi) * Math.sin(theta);
      positions[i * 3 + 2] = distance * Math.cos(phi);
      
      particleSizes[i] = Math.random() * 2;

      const color = new THREE.Color();
      color.setHSL(0.5 + Math.random() * 0.2, 0.7, 0.5);
      colors[i * 3] = color.r;
      colors[i * 3 + 1] = color.g;
      colors[i * 3 + 2] = color.b;
    }

    particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    particlesGeometry.setAttribute('size', new THREE.BufferAttribute(particleSizes, 1));
    particlesGeometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));

    const particlesMaterial = new THREE.PointsMaterial({
      size: 0.05,
      transparent: true,
      opacity: 0.4,
      vertexColors: true,
      blending: THREE.AdditiveBlending,
      sizeAttenuation: true
    });

    const particles = new THREE.Points(particlesGeometry, particlesMaterial);
    particlesRef.current = particles;
    scene.add(particles);

    // Add meshes to scene
    scene.add(mainGroup);
    scene.add(glowGroup);
    meshRef.current = mainGroup;
    glowMeshRef.current = glowGroup;

    // Dynamic lighting system
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    const pointLight1 = new THREE.PointLight(0x00a8cc, 1, 100);
    const pointLight2 = new THREE.PointLight(0x0088ff, 1, 100);
    pointLight1.position.set(5, 5, 5);
    pointLight2.position.set(-5, -5, -5);
    scene.add(ambientLight, pointLight1, pointLight2);

    // Enhanced mouse interaction
    const handleMouseMove = (event: MouseEvent) => {
      mousePosition.current = {
        x: (event.clientX / window.innerWidth) * 2 - 1,
        y: -(event.clientY / window.innerHeight) * 2 + 1
      };
    };

    // Responsive handling
    const handleResize = () => {
      if (!cameraRef.current || !rendererRef.current) return;

      cameraRef.current.aspect = window.innerWidth / window.innerHeight;
      cameraRef.current.updateProjectionMatrix();
      rendererRef.current.setSize(window.innerWidth, window.innerHeight);
      rendererRef.current.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    };

    // Advanced animation loop
    const animate = () => {
      frameIdRef.current = requestAnimationFrame(animate);

      if (!meshRef.current || !glowMeshRef.current || !particlesRef.current || 
          !energyFieldRef.current || !rendererRef.current || !sceneRef.current || 
          !cameraRef.current) return;

      const time = Date.now() * 0.001;

      // Complex rotation patterns
      meshRef.current.rotation.x += 0.001;
      meshRef.current.rotation.y += 0.002;
      meshRef.current.rotation.z += Math.sin(time * 0.5) * 0.001;

      // Individual layer animations
      meshRef.current.children.forEach((layer, index) => {
        layer.rotation.x += 0.001 * (index + 1);
        layer.rotation.y += 0.001 * Math.sin(time * 0.2) * (index + 1);
      });
      
      glowMeshRef.current.rotation.copy(meshRef.current.rotation);

      // Energy field animation
      energyFieldRef.current.rotation.y += 0.0003;
      const energyFieldSizes = energyFieldRef.current.geometry.attributes.size.array as Float32Array;
      for (let i = 0; i < energyFieldSizes.length; i++) {
        energyFieldSizes[i] = Math.sin(time + i) * 0.1 + 0.1;
      }
      energyFieldRef.current.geometry.attributes.size.needsUpdate = true;

      // Particle system animation
      particlesRef.current.rotation.y += 0.0002;
      const particleSizes = particlesRef.current.geometry.attributes.size.array as Float32Array;
      const positions = particlesRef.current.geometry.attributes.position.array as Float32Array;
      
      for (let i = 0; i < positions.length; i += 3) {
        const distance = Math.sqrt(
          positions[i] ** 2 + 
          positions[i + 1] ** 2 + 
          positions[i + 2] ** 2
        );
        
        particleSizes[i / 3] = Math.sin(time + distance) * 0.1 + 0.1;
      }
      particlesRef.current.geometry.attributes.size.needsUpdate = true;

      // Smooth mouse interaction
      const targetRotationX = mousePosition.current.y * 0.5;
      const targetRotationY = mousePosition.current.x * 0.5;
      
      meshRef.current.rotation.x += (targetRotationX - meshRef.current.rotation.x) * 0.05;
      meshRef.current.rotation.y += (targetRotationY - meshRef.current.rotation.y) * 0.05;
      
      glowMeshRef.current.rotation.copy(meshRef.current.rotation);

      // Dynamic glow and pulse effects
      const pulseScale = 1 + Math.sin(time * 2) * 0.05;
      glowMeshRef.current.scale.set(pulseScale, pulseScale, pulseScale);

      // Cinematic camera movement
      camera.position.x += (mousePosition.current.x * 0.5 - camera.position.x) * 0.05;
      camera.position.y += (-mousePosition.current.y * 0.5 - camera.position.y) * 0.05;
      camera.lookAt(scene.position);

      renderer.render(scene, camera);
    };

    animate();

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('resize', handleResize);

    return () => {
      if (containerRef.current && rendererRef.current) {
        containerRef.current.removeChild(rendererRef.current.domElement);
      }
      
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('resize', handleResize);
      
      cancelAnimationFrame(frameIdRef.current);
      
      // Cleanup all geometries and materials
      [meshRef.current, glowMeshRef.current, particlesRef.current, energyFieldRef.current].forEach(obj => {
        if (obj) {
          if (obj instanceof THREE.Points) {
            obj.geometry.dispose();
            (obj.material as THREE.Material).dispose();
          } else if (obj instanceof THREE.Group) {
            obj.children.forEach(child => {
              if (child instanceof THREE.Mesh) {
                child.geometry.dispose();
                (child.material as THREE.Material).dispose();
              }
            });
          }
        }
      });
      
      rendererRef.current?.dispose();
    };
  }, []);

  return (
    <div 
      ref={containerRef} 
      className="absolute inset-0 pointer-events-none"
      style={{ zIndex: 0 }}
    />
  );
}