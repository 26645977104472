import React, { useEffect, useRef } from 'react';

interface Symbol {
  x: number;
  y: number;
  value: string;
  speed: number;
  opacity: number;
  color: string;
  size: number;
  trail: Array<{ value: string; y: number; opacity: number }>;
  trailLength: number;
}

export default function DigitalRainBackground() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const symbolsRef = useRef<Symbol[]>([]);
  const animationFrameRef = useRef<number>();

  useEffect(() => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d', { alpha: true });
    if (!ctx) return;

    // Matrix-style characters (katakana, numbers, and symbols)
    const characters = 'ｦｱｳｴｵｶｷｸｹｺｻｼｽｾｿﾀﾁﾂﾃﾄﾅﾆﾇﾈﾉﾊﾋﾌﾍﾎﾏﾐﾑﾒﾓﾔﾕﾖﾗﾘﾙﾚﾛﾜﾝ1234567890¦｜=+-*/<>⬡';
    
    // Brand colors with varying opacity levels
    const colors = [
      'rgba(0, 168, 204, 1)',  // Teal
      'rgba(147, 51, 234, 1)', // Purple
      'rgba(79, 70, 229, 1)'   // Indigo
    ];

    const initializeSymbols = () => {
      const symbols: Symbol[] = [];
      const columnWidth = 15;
      const columns = Math.floor(canvas.width / columnWidth);

      for (let i = 0; i < columns; i++) {
        symbols.push({
          x: i * columnWidth,
          y: Math.random() * canvas.height * -2,
          value: characters[Math.floor(Math.random() * characters.length)],
          speed: 1 + Math.random() * 2,
          opacity: 0.8 + Math.random() * 0.2,
          color: colors[Math.floor(Math.random() * colors.length)],
          size: 14 + Math.floor(Math.random() * 4),
          trail: [],
          trailLength: 15 + Math.floor(Math.random() * 20)
        });
      }

      symbolsRef.current = symbols;
    };

    const updateSymbols = () => {
      symbolsRef.current.forEach(symbol => {
        if (symbol.y > 0) {
          const trailValue = Math.random() < 0.1 ? 
            characters[Math.floor(Math.random() * characters.length)] : 
            symbol.value;

          symbol.trail.push({
            value: trailValue,
            y: symbol.y,
            opacity: 1
          });
        }

        if (symbol.trail.length > symbol.trailLength) {
          symbol.trail.shift();
        }

        symbol.trail.forEach((t, i) => {
          t.opacity = 1 - (i / symbol.trailLength);
        });

        symbol.y += symbol.speed;

        // Fade out symbols as they approach the footer
        const footerStart = canvas.height - 300; // Start fading 300px from bottom
        if (symbol.y > footerStart) {
          const fadeRatio = 1 - ((symbol.y - footerStart) / 300);
          symbol.opacity = Math.max(0, fadeRatio);
        } else {
          symbol.opacity = 1;
        }

        if (symbol.y > canvas.height) {
          symbol.y = -50 - Math.random() * 100;
          symbol.trail = [];
          symbol.speed = 1 + Math.random() * 2;
          symbol.trailLength = 15 + Math.floor(Math.random() * 20);
        }

        if (Math.random() < 0.05) {
          symbol.value = characters[Math.floor(Math.random() * characters.length)];
        }
      });
    };

    const draw = () => {
      ctx.fillStyle = 'rgba(0, 0, 0, 0.05)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      symbolsRef.current.forEach(symbol => {
        symbol.trail.forEach((trail, i) => {
          ctx.font = `${symbol.size}px "Source Code Pro", monospace`;
          ctx.textAlign = 'center';
          
          ctx.shadowBlur = 8;
          ctx.shadowColor = symbol.color.replace('1)', '0.5)');
          
          const trailColor = symbol.color.replace('1)', `${trail.opacity * 0.8 * symbol.opacity})`);
          ctx.fillStyle = trailColor;
          ctx.fillText(trail.value, symbol.x, trail.y);
        });

        if (symbol.y > 0) {
          ctx.font = `${symbol.size}px "Source Code Pro", monospace`;
          ctx.textAlign = 'center';
          
          ctx.shadowBlur = 15;
          ctx.shadowColor = `rgba(255, 255, 255, ${0.8 * symbol.opacity})`;
          ctx.fillStyle = `rgba(255, 255, 255, ${0.95 * symbol.opacity})`;
          ctx.fillText(symbol.value, symbol.x, symbol.y);
        }
      });

      ctx.shadowBlur = 0;
    };

    const animate = () => {
      updateSymbols();
      draw();
      animationFrameRef.current = requestAnimationFrame(animate);
    };

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      initializeSymbols();
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    animate();

    return () => {
      window.removeEventListener('resize', handleResize);
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      className="fixed inset-0 pointer-events-none opacity-80"
      style={{ zIndex: 0 }}
    />
  );
}