import React, { useState, useEffect } from 'react';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import CalculatorForm from './CalculatorForm';
import CalculatorResults from './CalculatorResults';
import { calculateROI } from './calculations';
import type { CalculatorInputs, ROIResults } from './types';

export default function Calculator() {
  const [inputs, setInputs] = useState<CalculatorInputs>({
    employees: 50,
    avgSalary: 45000,
    processTime: 40,
    errorRate: 5,
    industry: 'general'
  });

  const [results, setResults] = useState<ROIResults | null>(null);

  useEffect(() => {
    const newResults = calculateROI(inputs);
    setResults(newResults);
  }, [inputs]);

  return (
    <div className="max-w-5xl mx-auto">
      <div className="grid lg:grid-cols-2 gap-12">
        <CalculatorForm inputs={inputs} setInputs={setInputs} />
        {results && <CalculatorResults results={results} />}
      </div>

      <div className="text-center mt-16">
        <Link 
          to="/contact" 
          className="inline-flex items-center px-8 py-4 text-lg font-medium rounded-md text-white hover:text-white bg-gradient-to-r from-teal-500 to-teal-600 hover:from-teal-600 hover:to-teal-700 transition-all duration-200 shadow-lg hover:shadow-xl transform hover:-translate-y-0.5"
        >
          <span>Get Your Custom ROI Analysis</span>
          <ArrowRight className="ml-2 h-5 w-5 transition-transform group-hover:translate-x-1" />
        </Link>
      </div>
    </div>
  );
}