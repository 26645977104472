import React, { useState } from 'react';
import { ArrowRight, ArrowLeft } from 'lucide-react';
import { questions } from './questions';
import type { Answer } from './types';
import ResultsCard from './ResultsCard';
import LeadForm from './LeadForm';

export default function AssessmentTool() {
  const [currentStep, setCurrentStep] = useState(0);
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [showResults, setShowResults] = useState(false);
  const [showLeadForm, setShowLeadForm] = useState(false);

  const handleAnswer = (score: number) => {
    const newAnswers = [...answers];
    newAnswers[currentStep] = {
      questionId: currentStep,
      score
    };
    setAnswers(newAnswers);

    if (currentStep < questions.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      setShowResults(true);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const calculateScore = () => {
    const totalScore = answers.reduce((acc, curr) => acc + curr.score, 0);
    const maxScore = questions.length * 5;
    return Math.round((totalScore / maxScore) * 100);
  };

  if (showLeadForm) {
    return <LeadForm score={calculateScore()} />;
  }

  if (showResults) {
    return (
      <ResultsCard 
        score={calculateScore()} 
        onRequestConsultation={() => setShowLeadForm(true)} 
      />
    );
  }

  const currentQuestion = questions[currentStep];

  return (
    <div className="max-w-3xl mx-auto">
      <div className="glass-card p-8 rounded-xl backdrop-blur-md">
        <div className="mb-8">
          <div className="flex justify-between items-center mb-4">
            <span className="text-sm text-gray-400">Question {currentStep + 1} of {questions.length}</span>
            <span className="text-sm text-gray-400">{Math.round((currentStep / questions.length) * 100)}% Complete</span>
          </div>
          <div className="w-full bg-gray-700 rounded-full h-2">
            <div 
              className="bg-gradient-to-r from-teal-500 to-purple-500 h-2 rounded-full transition-all duration-300"
              style={{ width: `${(currentStep / questions.length) * 100}%` }}
            />
          </div>
        </div>

        <h3 className="text-2xl font-bold text-white mb-8">{currentQuestion.text}</h3>

        <div className="space-y-4">
          {currentQuestion.options.map((option, index) => (
            <button
              key={index}
              onClick={() => handleAnswer(option.score)}
              className="w-full text-left p-4 rounded-lg bg-gray-800 hover:bg-gray-700 transition-colors border border-gray-700 hover:border-teal-500 focus:outline-none focus:ring-2 focus:ring-teal-500"
            >
              <p className="text-white font-medium">{option.text}</p>
              {option.description && (
                <p className="text-sm text-gray-400 mt-1">{option.description}</p>
              )}
            </button>
          ))}
        </div>

        {currentStep > 0 && (
          <button
            onClick={handleBack}
            className="mt-8 inline-flex items-center text-gray-400 hover:text-white transition-colors"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Previous Question
          </button>
        )}
      </div>
    </div>
  );
}