import React from 'react';

interface ProjectDetailsProps {
  formData: {
    queryType: string;
  };
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export default function ProjectDetails({ formData, handleChange }: ProjectDetailsProps) {
  return (
    <div className="glass-card p-6 rounded-xl backdrop-blur-md">
      <h2 className="text-2xl font-bold text-white mb-6">Project Details</h2>
      <div>
        <label htmlFor="queryType" className="block text-sm font-medium text-gray-300 mb-1">
          What type of query brings you to us today?*
        </label>
        <select
          id="queryType"
          name="queryType"
          value={formData.queryType}
          onChange={handleChange}
          required
          className="w-full px-4 py-2 rounded-md bg-gray-800 border border-gray-700 text-white focus:ring-2 focus:ring-teal-500 focus:border-transparent"
        >
          <option value="">Select an option</option>
          <option value="general">General Inquiry</option>
          <option value="sales">Sales Question</option>
          <option value="support">Technical Support</option>
          <option value="partnership">Partnership Opportunity</option>
          <option value="dpo">Data Protection (DPO)</option>
          <option value="other">Other</option>
        </select>
      </div>
    </div>
  );
}