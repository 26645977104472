import React from 'react';
import { Target, Users, Zap } from 'lucide-react';

const goals = [
  {
    icon: Target,
    title: 'Empowering Small Businesses',
    description: 'Making enterprise-level AI tools accessible to businesses of all sizes'
  },
  {
    icon: Users,
    title: 'Personal Understanding',
    description: 'Solutions built from real experience and understanding of business challenges'
  },
  {
    icon: Zap,
    title: 'Continuous Innovation',
    description: 'Constantly evolving our solutions to meet emerging business needs'
  }
];

export default function MissionSection() {
  return (
    <section className="relative py-12">
      <div className="absolute inset-0 bg-gradient-to-b from-gray-900/90 via-gray-900/85 to-gray-900/90" />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <div className="glass-card p-8 rounded-xl backdrop-blur-md">
          <div className="max-w-3xl mx-auto text-center mb-12">
            <h2 className="text-4xl font-bold mb-6">
              <span className="text-gradient">Our Mission Today</span>
            </h2>
            <div className="space-y-6 text-lg text-gray-300">
              <p className="leading-relaxed">
                In 2023, this natural evolution of helping others led to the founding of TacticalSolutions.ai. Our mission became clear: to empower small businesses with the same powerful AI and automation tools that transformed Anthony's own business operations.
              </p>
              <p className="leading-relaxed">
                Today, we combine personal understanding of business challenges with technical expertise to deliver solutions that make a real difference. Our approach isn't just about implementing technology – it's about creating breathing space for business owners to focus on what truly matters.
              </p>
              <blockquote className="border-l-4 border-teal-500 pl-4 my-6 italic text-gray-200">
                "We're not just building technology; we're creating freedom for business owners to be present in all aspects of their lives."
              </blockquote>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {goals.map((goal, index) => (
              <div
                key={index}
                className="glass-card p-6 rounded-xl backdrop-blur-md transform hover:scale-105 transition-all duration-300"
              >
                <div className="flex flex-col items-center text-center">
                  <div className="w-12 h-12 rounded-lg bg-gradient-to-br from-teal-500 to-purple-500 p-2.5 flex items-center justify-center mb-4">
                    <goal.icon className="w-full h-full text-white" />
                  </div>
                  <h3 className="text-xl font-bold text-white mb-2">{goal.title}</h3>
                  <p className="text-gray-300">{goal.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}