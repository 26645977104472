import React from 'react';

interface TechnicalRequirementsProps {
  formData: {
    solutions: string[];
    currentTools: string;
  };
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export default function TechnicalRequirements({ formData, handleChange }: TechnicalRequirementsProps) {
  const solutions = [
    'Workflow Automation',
    'Business Process Management',
    'Data Integration',
    'Document Processing',
    'Business Intelligence',
    'Custom Solutions'
  ];

  return (
    <div className="glass-card p-6 rounded-xl backdrop-blur-md">
      <h2 className="text-2xl font-bold text-white mb-6">Technical Requirements</h2>
      <div className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-3">
            Which no-code/low-code solutions interest you?* (Select all that apply)
          </label>
          <div className="space-y-2">
            {solutions.map((solution) => (
              <label key={solution} className="flex items-center space-x-3">
                <input
                  type="checkbox"
                  name="solutions"
                  value={solution}
                  checked={formData.solutions.includes(solution)}
                  onChange={(e) => {
                    const value = e.target.value;
                    handleChange({
                      target: {
                        name: 'solutions',
                        value: formData.solutions.includes(value)
                          ? formData.solutions.filter(s => s !== value)
                          : [...formData.solutions, value]
                      }
                    } as any);
                  }}
                  className="form-checkbox h-5 w-5 text-teal-500 rounded border-gray-700 bg-gray-800 focus:ring-2 focus:ring-teal-500"
                />
                <span className="text-gray-300">{solution}</span>
              </label>
            ))}
          </div>
        </div>
        <div>
          <label htmlFor="currentTools" className="block text-sm font-medium text-gray-300 mb-1">
            What tools or platforms are you currently using?
          </label>
          <textarea
            id="currentTools"
            name="currentTools"
            value={formData.currentTools}
            onChange={handleChange}
            rows={4}
            className="w-full px-4 py-2 rounded-md bg-gray-800 border border-gray-700 text-white focus:ring-2 focus:ring-teal-500 focus:border-transparent"
          />
        </div>
      </div>
    </div>
  );
}