import React from 'react';

interface AdditionalInfoProps {
  formData: {
    additionalInfo: string;
  };
  handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export default function AdditionalInfo({ formData, handleChange }: AdditionalInfoProps) {
  return (
    <div className="glass-card p-6 rounded-xl backdrop-blur-md">
      <h2 className="text-2xl font-bold text-white mb-6">Additional Information</h2>
      <div>
        <label htmlFor="additionalInfo" className="block text-sm font-medium text-gray-300 mb-1">
          Any additional details or specific requirements you'd like to share?
        </label>
        <textarea
          id="additionalInfo"
          name="additionalInfo"
          value={formData.additionalInfo}
          onChange={handleChange}
          rows={6}
          className="w-full px-4 py-2 rounded-md bg-gray-800 border border-gray-700 text-white focus:ring-2 focus:ring-teal-500 focus:border-transparent"
          placeholder="Share any other relevant information about your project..."
        />
      </div>
    </div>
  );
}