import React from 'react';
import { Clock, TrendingUp, DollarSign, Zap } from 'lucide-react';
import type { ROIResults } from './types';

interface CalculatorResultsProps {
  results: ROIResults;
}

export default function CalculatorResults({ results }: CalculatorResultsProps) {
  return (
    <div className="glass-card p-8 rounded-xl backdrop-blur-md">
      <h2 className="text-2xl font-bold text-white mb-8">Projected Annual Benefits</h2>

      <div className="space-y-8">
        <div className="flex items-center space-x-4">
          <div className="w-12 h-12 rounded-lg bg-gradient-to-br from-teal-500/20 to-purple-500/20 p-2.5 flex items-center justify-center">
            <Clock className="h-6 w-6 text-teal-400" />
          </div>
          <div>
            <div className="text-3xl font-bold text-white">{results.timeSavings} hrs</div>
            <div className="text-sm text-gray-400">Annual Time Savings</div>
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <div className="w-12 h-12 rounded-lg bg-gradient-to-br from-teal-500/20 to-purple-500/20 p-2.5 flex items-center justify-center">
            <DollarSign className="h-6 w-6 text-teal-400" />
          </div>
          <div>
            <div className="text-3xl font-bold text-white">£{results.costSavings.toLocaleString()}</div>
            <div className="text-sm text-gray-400">Annual Cost Savings</div>
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <div className="w-12 h-12 rounded-lg bg-gradient-to-br from-teal-500/20 to-purple-500/20 p-2.5 flex items-center justify-center">
            <TrendingUp className="h-6 w-6 text-teal-400" />
          </div>
          <div>
            <div className="text-3xl font-bold text-white">{results.errorReduction}%</div>
            <div className="text-sm text-gray-400">Error Reduction</div>
          </div>
        </div>

        <div className="flex items-center space-x-4">
          <div className="w-12 h-12 rounded-lg bg-gradient-to-br from-teal-500/20 to-purple-500/20 p-2.5 flex items-center justify-center">
            <Zap className="h-6 w-6 text-teal-400" />
          </div>
          <div>
            <div className="text-3xl font-bold text-white">{results.roi}%</div>
            <div className="text-sm text-gray-400">Projected ROI</div>
          </div>
        </div>

        <div className="mt-8 pt-8 border-t border-gray-700">
          <div className="text-sm text-gray-400">
            * Estimates based on industry averages and actual client results. Your actual results may vary.
          </div>
        </div>
      </div>
    </div>
  );
}