import React from 'react';

interface LogoProps {
  className?: string;
  variant?: 'default' | 'large';
  theme?: 'light' | 'dark';
}

export default function Logo({ 
  className = "", 
  variant = 'default',
  theme = 'light'
}: LogoProps) {
  const logoUrl = "https://stackblitz.com/storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBN0toRmc9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--8ed48ca1e1f8032e164818e9164ed73696e6ab53/Copy%20of%20HS%20Alwafa%20digital.png";

  if (variant === 'large') {
    return (
      <div className={`flex flex-col items-center ${className}`}>
        <img 
          src={logoUrl}
          alt="TacticalSolutions.ai Logo"
          className="h-32 w-auto object-contain mb-8"
        />
        <h2 className="text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-teal-400 to-purple-400">
          TacticalSolutions.ai
        </h2>
      </div>
    );
  }

  return (
    <div className={`flex items-center ${className}`}>
      <img 
        src={logoUrl}
        alt="TacticalSolutions.ai Logo"
        className="h-8 w-auto object-contain"
      />
      <span className={`ml-3 text-lg font-bold ${
        theme === 'light' ? 'text-white' : 'text-gray-900 dark:text-white'
      }`}>
        TacticalSolutions.ai
      </span>
    </div>
  );
}