import React from 'react';
import { Brain, Lightbulb, BookOpen } from 'lucide-react';

export default function ExplainersHero() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-20">
      <div className="text-center">
        <h1 className="text-5xl md:text-7xl font-bold mb-6">
          <span className="text-gradient">Understanding AI</span>
        </h1>
        <p className="text-xl text-gray-300 max-w-3xl mx-auto mb-12">
          Clear explanations of complex AI concepts to help you make informed decisions about implementing AI in your business
        </p>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-4xl mx-auto">
          <div className="glass-card p-6 rounded-xl backdrop-blur-md transform hover:scale-105 transition-all duration-300">
            <Brain className="h-8 w-8 text-teal-400 mx-auto mb-4" />
            <h3 className="text-white font-semibold mb-2">Clear Concepts</h3>
            <p className="text-gray-400 text-sm">Complex ideas explained simply</p>
          </div>
          <div className="glass-card p-6 rounded-xl backdrop-blur-md transform hover:scale-105 transition-all duration-300">
            <Lightbulb className="h-8 w-8 text-teal-400 mx-auto mb-4" />
            <h3 className="text-white font-semibold mb-2">Practical Examples</h3>
            <p className="text-gray-400 text-sm">Real-world applications</p>
          </div>
          <div className="glass-card p-6 rounded-xl backdrop-blur-md transform hover:scale-105 transition-all duration-300">
            <BookOpen className="h-8 w-8 text-teal-400 mx-auto mb-4" />
            <h3 className="text-white font-semibold mb-2">Business Context</h3>
            <p className="text-gray-400 text-sm">Industry-specific insights</p>
          </div>
        </div>
      </div>
    </div>
  );
}