import React from 'react';
import { Mail, Clock, Shield } from 'lucide-react';

export default function ContactHero() {
  return (
    <div className="text-center max-w-3xl mx-auto">
      <h1 className="text-5xl font-bold mb-6">
        <span className="text-gradient">Let's Build Something Amazing</span>
      </h1>
      <p className="text-xl text-gray-300 leading-relaxed mb-12">
        Share your vision with us, and we'll help you transform it into reality. Our team is ready to understand your unique challenges and create tailored AI solutions that drive real results.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="glass-card p-6 rounded-xl backdrop-blur-md transform hover:scale-105 transition-all duration-300">
          <Mail className="w-6 h-6 text-teal-400 mx-auto mb-3" />
          <h3 className="text-white font-semibold mb-2">Email Support</h3>
          <p className="text-gray-400 text-sm">24/7 availability</p>
        </div>
        <div className="glass-card p-6 rounded-xl backdrop-blur-md transform hover:scale-105 transition-all duration-300">
          <Clock className="w-6 h-6 text-teal-400 mx-auto mb-3" />
          <h3 className="text-white font-semibold mb-2">Fast Response</h3>
          <p className="text-gray-400 text-sm">Average: 24 hours</p>
        </div>
        <div className="glass-card p-6 rounded-xl backdrop-blur-md transform hover:scale-105 transition-all duration-300">
          <Shield className="w-6 h-6 text-teal-400 mx-auto mb-3" />
          <h3 className="text-white font-semibold mb-2">Secure Process</h3>
          <p className="text-gray-400 text-sm">End-to-end encryption</p>
        </div>
      </div>
    </div>
  );
}