import React from 'react';
import SEO from '../components/SEO';
import Calculator from '../components/roi/Calculator';
import ROIHero from '../components/roi/ROIHero';
import NeuralBackground from '../components/NeuralBackground';

export default function ROICalculator() {
  return (
    <>
      <SEO 
        title="AI ROI Calculator"
        description="Calculate the potential return on investment for implementing AI automation solutions in your business. Make informed decisions with our comprehensive ROI analysis tool."
        keywords={[
          'AI ROI Calculator',
          'Return on Investment',
          'Business Analytics',
          'AI Implementation',
          'Cost Savings',
          'Process Efficiency',
          'Business Value',
          'AI Benefits',
          'Investment Analysis',
          'Automation ROI'
        ]}
      />
      <div className="relative min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 pt-32 pb-20">
        <div className="absolute inset-0">
          <NeuralBackground />
        </div>
        <div className="relative">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <ROIHero />
            <Calculator />
          </div>
        </div>
      </div>
    </>
  );
}