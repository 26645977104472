import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Brain, 
  Blocks, 
  Cpu, 
  Database, 
  Bot, 
  Grid, 
  MessageSquare, 
  Network, 
  Search, 
  Workflow,
  Zap 
} from 'lucide-react';

const technologies = [
  {
    name: 'Groq',
    icon: Cpu,
    description: 'Ultra-fast LPU inference engine for enhanced AI processing speed and efficiency.',
    category: 'AI Infrastructure',
    features: ['1ms latency', 'LPU architecture', 'Parallel processing'],
    gradient: 'from-emerald-500/20 to-green-600/20'
  },
  {
    name: 'Voiceflow',
    icon: MessageSquare,
    description: 'Enterprise-grade conversational AI platform for automated customer interactions.',
    category: 'Voice AI',
    features: ['Visual builder', 'Multi-channel', 'Analytics'],
    gradient: 'from-blue-500/20 to-violet-500/20'
  },
  {
    name: 'Botpress',
    icon: Bot,
    description: 'Open-source chatbot platform for scalable business automation solutions.',
    category: 'Conversational AI',
    features: ['NLU engine', 'Visual flows', 'Enterprise-ready'],
    gradient: 'from-purple-500/20 to-pink-500/20'
  },
  {
    name: 'Perplexity',
    icon: Search,
    description: 'AI-powered knowledge engine for real-time information analysis and retrieval.',
    category: 'Search AI',
    features: ['Real-time search', 'Citation tracking', 'Multi-source'],
    gradient: 'from-indigo-500/20 to-blue-600/20'
  },
  {
    name: 'Flowise',
    icon: Workflow,
    description: 'AI workflow automation platform for seamless business process integration.',
    category: 'AI Automation',
    features: ['Drag-n-drop', 'LangChain UI', 'Custom nodes'],
    gradient: 'from-amber-500/20 to-orange-600/20'
  },
  {
    name: 'Claude',
    icon: Brain,
    description: 'Advanced AI assistant for sophisticated business process automation.',
    category: 'AI',
    features: ['Constitutional AI', 'Code analysis', 'Long context'],
    gradient: 'from-purple-500/20 to-pink-500/20'
  },
  {
    name: 'Notion',
    icon: Grid,
    description: 'All-in-one workspace with powerful API integration capabilities.',
    category: 'Productivity',
    features: ['API access', 'Databases', 'Collaboration'],
    gradient: 'from-gray-700/20 to-gray-900/20'
  },
  {
    name: 'Airtable',
    icon: Database,
    description: 'Flexible database platform for automated business operations.',
    category: 'Data',
    features: ['Automations', 'API-first', 'Custom views'],
    gradient: 'from-green-500/20 to-emerald-600/20'
  },
  {
    name: 'n8n',
    icon: Network,
    description: 'Powerful workflow automation platform for enterprise integration.',
    category: 'Automation',
    features: ['1000+ nodes', 'Self-hosted', 'Enterprise-ready'],
    gradient: 'from-orange-500/20 to-red-500/20'
  },
  {
    name: 'Make',
    icon: Zap,
    description: 'Enterprise automation platform for seamless system integration.',
    category: 'Integration',
    features: ['2000+ apps', 'No-code', 'Real-time execution'],
    gradient: 'from-blue-500/20 to-indigo-500/20'
  },
  {
    name: 'Zapier',
    icon: Blocks,
    description: 'Cloud automation platform for business process optimization.',
    category: 'Integration',
    features: ['5000+ apps', 'Templates', 'Enterprise-ready'],
    gradient: 'from-orange-500/20 to-red-500/20'
  }
];

function TechnologyCard({ tech }: { tech: typeof technologies[0] }) {
  return (
    <div className="group relative flex">
      <div className="relative bg-white rounded-xl transition-all duration-500 transform-gpu hover:shadow-2xl hover:shadow-teal-500/10 border border-gray-100 flex flex-col w-full">
        <div className="magnetic-glow" />
        
        <div className="relative z-10 flex flex-col h-full p-8">
          <div className="h-16 mb-6">
            <div className={`
              w-16 h-16 rounded-xl
              bg-gradient-to-br ${tech.gradient}
              flex items-center justify-center
              group-hover:scale-110 transition-transform duration-500
            `}>
              <tech.icon className="h-8 w-8 text-gray-900" />
            </div>
          </div>

          <div className="h-14 mb-4">
            <h3 className="text-2xl font-bold text-gray-900 group-hover:text-teal-600 leading-tight">
              {tech.name}
            </h3>
          </div>
          
          <div className="h-24 mb-10">
            <p className="text-gray-600 text-lg leading-snug">
              {tech.description}
            </p>
          </div>

          <div className="mt-auto space-y-4">
            {tech.features.map((feature, i) => (
              <div key={i} className="flex items-center text-sm text-teal-600">
                <div className="w-1.5 h-1.5 rounded-full bg-teal-500 mr-2" />
                {feature}
              </div>
            ))}
          </div>
        </div>

        <div className="card-shadow" />
      </div>
    </div>
  );
}

export default function Technologies() {
  return (
    <section className="py-24 bg-white relative">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-20">
          <h2 className="text-5xl font-bold mb-6">
            <span className="text-gradient">Empowering Technologies</span>
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            We leverage cutting-edge tools to make AI accessible and effective for businesses of all sizes
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
          {technologies.slice(0, 4).map((tech) => (
            <TechnologyCard key={tech.name} tech={tech} />
          ))}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
          {technologies.slice(4, 8).map((tech) => (
            <TechnologyCard key={tech.name} tech={tech} />
          ))}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-5xl mx-auto mb-16">
          {technologies.slice(8).map((tech) => (
            <TechnologyCard key={tech.name} tech={tech} />
          ))}
        </div>

        <div className="relative z-10 text-center mt-8">
          <Link 
            to="/ai-readiness"
            className="inline-flex items-center justify-center w-full max-w-md px-12 py-4 text-lg font-medium rounded-md text-white bg-gradient-to-r from-teal-500 to-teal-600 hover:from-teal-600 hover:to-teal-700 transition-colors duration-200 shadow-lg hover:shadow-xl mx-auto"
          >
            <Brain className="h-5 w-5 mr-2" />
            <span>Check Your AI Readiness</span>
          </Link>
        </div>
      </div>
    </section>
  );
}