import React, { useState } from 'react';
import { ArrowRight, X } from 'lucide-react';

interface Position {
  title: string;
  department: string;
  location: string;
  type: string;
  description: string;
}

const positions: Position[] = [
  {
    title: 'AI Solutions Architect',
    department: 'Engineering',
    location: 'Remote',
    type: 'Full-time',
    description: 'Design and implement AI-powered automation solutions for enterprise clients.'
  },
  {
    title: 'Senior Frontend Developer',
    department: 'Engineering',
    location: 'Remote',
    type: 'Full-time',
    description: 'Build beautiful, responsive interfaces for our AI platforms using React.'
  },
  {
    title: 'Machine Learning Engineer',
    department: 'AI Research',
    location: 'Remote',
    type: 'Full-time',
    description: 'Develop and optimize machine learning models for business automation.'
  },
  {
    title: 'Product Manager',
    department: 'Product',
    location: 'Remote',
    type: 'Full-time',
    description: 'Lead the development of innovative AI products from conception to launch.'
  }
];

export default function OpenPositions() {
  const [selectedPosition, setSelectedPosition] = useState<Position | null>(null);

  return (
    <section className="py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-bold text-center mb-12">
          <span className="text-gradient">Open Positions</span>
        </h2>

        <div className="grid gap-6">
          {positions.map((position, index) => (
            <div
              key={index}
              className="glass-card p-6 rounded-xl backdrop-blur-md hover:scale-[1.02] transition-transform duration-300"
            >
              <div className="flex flex-col md:flex-row md:items-center justify-between">
                <div className="mb-4 md:mb-0">
                  <h3 className="text-xl font-bold text-white mb-2">{position.title}</h3>
                  <div className="flex flex-wrap gap-2 mb-2">
                    <span className="px-3 py-1 rounded-full text-sm bg-teal-500/10 text-teal-400">
                      {position.department}
                    </span>
                    <span className="px-3 py-1 rounded-full text-sm bg-purple-500/10 text-purple-400">
                      {position.location}
                    </span>
                    <span className="px-3 py-1 rounded-full text-sm bg-blue-500/10 text-blue-400">
                      {position.type}
                    </span>
                  </div>
                  <p className="text-gray-300">{position.description}</p>
                </div>
                <button 
                  onClick={() => setSelectedPosition(position)}
                  className="inline-flex items-center px-8 py-3 text-lg font-medium rounded-md text-white hover:text-white bg-gradient-to-r from-teal-500 to-teal-600 hover:from-teal-600 hover:to-teal-700 transition-all duration-200"
                >
                  <span>Apply Now</span>
                  <ArrowRight className="ml-2 h-4 w-4" />
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* Application Modal */}
        {selectedPosition && (
          <div className="fixed inset-0 z-50 overflow-y-auto flex items-center justify-center">
            <div className="fixed inset-0 bg-gray-900 opacity-75"></div>
            
            <div className="glass-card rounded-xl shadow-xl transform transition-all w-full max-w-4xl mx-4 relative z-50">
              <div className="p-8">
                <div className="flex justify-between items-start mb-8">
                  <div>
                    <h3 className="text-3xl font-bold text-white mb-2">
                      Apply for {selectedPosition.title}
                    </h3>
                    <p className="text-gray-400">
                      {selectedPosition.department} · {selectedPosition.location} · {selectedPosition.type}
                    </p>
                  </div>
                  <button
                    onClick={() => setSelectedPosition(null)}
                    className="text-gray-400 hover:text-white transition-colors p-1"
                  >
                    <X className="h-6 w-6" />
                  </button>
                </div>
                
                <form className="space-y-8">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium text-gray-300 mb-2">
                        Full Name*
                      </label>
                      <input
                        type="text"
                        id="name"
                        required
                        className="w-full rounded-md bg-gray-800 border border-gray-700 text-white px-4 py-3 focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                      />
                    </div>
                    
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-2">
                        Email*
                      </label>
                      <input
                        type="email"
                        id="email"
                        required
                        className="w-full rounded-md bg-gray-800 border border-gray-700 text-white px-4 py-3 focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                      />
                    </div>
                  </div>
                  
                  <div>
                    <label htmlFor="phone" className="block text-sm font-medium text-gray-300 mb-2">
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      className="w-full rounded-md bg-gray-800 border border-gray-700 text-white px-4 py-3 focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                    />
                  </div>
                  
                  <div>
                    <label htmlFor="resume" className="block text-sm font-medium text-gray-300 mb-2">
                      Resume/CV*
                    </label>
                    <input
                      type="file"
                      id="resume"
                      required
                      accept=".pdf,.doc,.docx"
                      className="w-full text-gray-300 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-medium file:bg-gray-800 file:text-white hover:file:bg-gray-700 file:cursor-pointer"
                    />
                  </div>
                  
                  <div>
                    <label htmlFor="coverLetter" className="block text-sm font-medium text-gray-300 mb-2">
                      Cover Letter
                    </label>
                    <textarea
                      id="coverLetter"
                      rows={6}
                      className="w-full rounded-md bg-gray-800 border border-gray-700 text-white px-4 py-3 focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                      placeholder="Tell us why you're interested in this position..."
                    />
                  </div>
                </form>
              </div>
              
              <div className="px-8 py-6 bg-gray-800/50 rounded-b-xl flex flex-col sm:flex-row-reverse gap-4">
                <button
                  type="submit"
                  className="inline-flex items-center justify-center px-8 py-3 text-lg font-medium rounded-md text-white hover:text-white bg-gradient-to-r from-teal-500 to-teal-600 hover:from-teal-600 hover:to-teal-700 transition-all duration-200"
                >
                  Submit Application
                </button>
                <button
                  type="button"
                  onClick={() => setSelectedPosition(null)}
                  className="inline-flex items-center justify-center px-8 py-3 text-lg font-medium rounded-md text-white hover:text-white bg-gradient-to-r from-gray-600 to-gray-700 hover:from-gray-700 hover:to-gray-800 transition-all duration-200"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}