import React from 'react';
import { Calculator, TrendingUp, DollarSign } from 'lucide-react';

export default function ROIHero() {
  return (
    <div className="text-center max-w-3xl mx-auto mb-16">
      <h1 className="text-5xl font-bold mb-6">
        <span className="text-gradient">AI ROI Calculator</span>
      </h1>
      <p className="text-xl text-gray-300 mb-12">
        Estimate the potential return on investment for implementing AI automation solutions in your business
      </p>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="glass-card p-6 rounded-xl backdrop-blur-md">
          <Calculator className="h-8 w-8 text-teal-400 mx-auto mb-4" />
          <h3 className="text-white font-semibold mb-2">Easy Calculation</h3>
          <p className="text-gray-400 text-sm">Simple inputs for accurate estimates</p>
        </div>
        <div className="glass-card p-6 rounded-xl backdrop-blur-md">
          <TrendingUp className="h-8 w-8 text-teal-400 mx-auto mb-4" />
          <h3 className="text-white font-semibold mb-2">Real Metrics</h3>
          <p className="text-gray-400 text-sm">Based on actual client results</p>
        </div>
        <div className="glass-card p-6 rounded-xl backdrop-blur-md">
          <DollarSign className="h-8 w-8 text-teal-400 mx-auto mb-4" />
          <h3 className="text-white font-semibold mb-2">Clear Benefits</h3>
          <p className="text-gray-400 text-sm">See potential savings and gains</p>
        </div>
      </div>
    </div>
  );
}