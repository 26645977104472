import React from 'react';
import SEO from '../components/SEO';
import ExplainersHero from '../components/explainers/ExplainersHero';
import ConceptGrid from '../components/explainers/ConceptGrid';
import DetailedExplanations from '../components/explainers/DetailedExplanations';
import UseCases from '../components/explainers/UseCases';
import NeuralBackground from '../components/NeuralBackground';

export default function AIExplainers() {
  return (
    <>
      <SEO 
        title="AI Technology Explained"
        description="Understand complex AI concepts with clear, practical explanations. Learn how AI technology can transform your business operations and drive growth."
        keywords={[
          'AI Technology',
          'Machine Learning',
          'Neural Networks',
          'Business AI',
          'AI Implementation',
          'AI Solutions',
          'Technology Education',
          'AI Concepts',
          'Business Intelligence',
          'Digital Transformation'
        ]}
      />
      <div className="relative min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 pt-32 pb-20">
        <div className="absolute inset-0">
          <NeuralBackground />
        </div>
        <div className="relative">
          <ExplainersHero />
          <ConceptGrid />
          <DetailedExplanations />
          <UseCases />
        </div>
      </div>
    </>
  );
}