import React from 'react';
import { Building2 } from 'lucide-react';
import type { SuccessStory } from './types';
import StoryResults from './StoryResults';

interface StoryCardProps {
  story: SuccessStory;
}

export default function StoryCard({ story }: StoryCardProps) {
  return (
    <div className="group relative">
      <div className="absolute -inset-0.5 bg-gradient-to-r from-teal-500 to-purple-500 rounded-xl blur opacity-25 group-hover:opacity-30 transition duration-300" />
      <div className="relative bg-gray-800 rounded-xl p-8 transition-all duration-300 hover:translate-y-[-2px]">
        <div className="absolute inset-0 bg-gradient-to-br from-gray-800/50 to-gray-900/50 rounded-xl" />
        
        <div className="relative z-10">
          <div className="grid lg:grid-cols-2 gap-12">
            <div className="space-y-6">
              <div className="flex items-start space-x-4">
                <div className="w-12 h-12 rounded-lg bg-gradient-to-br from-teal-500/20 to-purple-500/20 p-2.5 flex items-center justify-center">
                  <Building2 className="h-6 w-6 text-teal-400" />
                </div>
                <div>
                  <h3 className="text-2xl font-bold text-white mb-1">{story.company}</h3>
                  <p className="text-gray-400">{story.location}</p>
                </div>
              </div>

              <div>
                <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-teal-500/10 text-teal-400 border border-teal-500/20">
                  {story.industry}
                </span>
              </div>

              <div className="space-y-4">
                <div>
                  <h4 className="text-lg font-semibold text-white mb-2">Challenge</h4>
                  <p className="text-gray-300">{story.challenge}</p>
                </div>
                <div>
                  <h4 className="text-lg font-semibold text-white mb-2">Solution</h4>
                  <p className="text-gray-300">{story.solution}</p>
                </div>
              </div>

              <blockquote className="border-l-4 border-teal-500 pl-4 italic text-gray-300">
                "{story.quote.text}"
                <footer className="mt-2 text-sm text-gray-400 not-italic">
                  <strong className="text-white">{story.quote.author}</strong> - {story.quote.role}
                </footer>
              </blockquote>
            </div>

            <StoryResults results={story.results} />
          </div>
        </div>
      </div>
    </div>
  );
}