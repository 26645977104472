import React from 'react';
import { Sparkles, Target, Zap } from 'lucide-react';

const achievements = [
  {
    icon: Target,
    title: 'Personal Challenge',
    description: 'Diagnosed with ADHD and becoming a full-time single father during COVID'
  },
  {
    icon: Sparkles,
    title: 'Innovation',
    description: 'Developed AI-driven solutions to manage complex business operations'
  },
  {
    icon: Zap,
    title: 'Transformation',
    description: 'Turned personal solutions into tools that help other entrepreneurs'
  }
];

export default function BirthOfSolution() {
  return (
    <section className="relative py-12">
      <div className="absolute inset-0 bg-gradient-to-b from-gray-900/90 via-gray-900/85 to-gray-900/90" />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <div className="glass-card p-8 rounded-xl backdrop-blur-md">
          <div className="max-w-3xl mx-auto text-center mb-12">
            <h2 className="text-4xl font-bold mb-6">
              <span className="text-gradient">The Birth of a Solution</span>
            </h2>
            <div className="space-y-6 text-lg text-gray-300">
              <p className="leading-relaxed">
                Drawing from his computing background and firsthand experience with ADHD, Anthony recognized the need to revolutionize how businesses handle routine operations. He began developing automated systems and AI-driven solutions that could manage the mundane yet crucial aspects of business management - tasks that he personally found challenging.
              </p>
              <p className="leading-relaxed">
                The effectiveness of these systems in his own businesses was remarkable. What started as a personal solution soon caught the attention of other business owners facing similar challenges. Anthony began sharing his expertise, helping other entrepreneurs implement efficient, automated systems in their operations.
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {achievements.map((achievement, index) => (
              <div
                key={index}
                className="glass-card p-6 rounded-xl backdrop-blur-md transform hover:scale-105 transition-all duration-300"
              >
                <div className="flex flex-col items-center text-center">
                  <div className="w-12 h-12 rounded-lg bg-gradient-to-br from-teal-500 to-purple-500 p-2.5 flex items-center justify-center mb-4">
                    <achievement.icon className="w-full h-full text-white" />
                  </div>
                  <h3 className="text-xl font-bold text-white mb-2">{achievement.title}</h3>
                  <p className="text-gray-300">{achievement.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}