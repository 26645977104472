import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Shield, Heart, Scale, Users, Brain, Lock, Eye, AlertTriangle } from 'lucide-react';

export default function EthicsPolicy() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 pt-24 pb-20">
      <Helmet>
        <title>AI Ethics Policy | TacticalSolutions.ai</title>
        <meta name="description" content="Our commitment to ethical AI development and implementation." />
      </Helmet>

      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header Section */}
        <div className="text-center mb-12">
          <div className="flex justify-center mb-6">
            <Scale className="h-16 w-16 text-teal-500" />
          </div>
          <h1 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-teal-400 to-purple-400">
            AI Ethics Policy
          </h1>
          <p className="text-gray-400">Last Updated: March 15, 2024</p>
        </div>

        {/* Core Principles Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
          {[
            { icon: Heart, title: 'Human-Centric', desc: 'Prioritizing human well-being in all AI implementations' },
            { icon: Shield, title: 'Responsible AI', desc: 'Ensuring safe and controlled AI development' },
            { icon: Eye, title: 'Transparency', desc: 'Clear communication about AI capabilities and limitations' },
            { icon: Lock, title: 'Privacy First', desc: 'Protecting user data and maintaining confidentiality' }
          ].map((item, i) => (
            <div key={i} className="glass-card p-6 rounded-xl backdrop-blur-md">
              <item.icon className="h-8 w-8 text-teal-500 mb-4" />
              <h3 className="text-xl font-bold text-white mb-2">{item.title}</h3>
              <p className="text-gray-300">{item.desc}</p>
            </div>
          ))}
        </div>

        {/* Main Content */}
        <div className="space-y-8">
          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">Our Ethical Framework</h2>
            <p className="text-gray-300 mb-4">
              At TacticalSolutions.ai, we are committed to developing and implementing AI solutions that adhere to the highest ethical standards. Our framework ensures responsible innovation while protecting individual rights and promoting societal benefit.
            </p>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">Core Commitments</h2>
            <div className="space-y-4 text-gray-300">
              <div className="flex items-start space-x-4">
                <Users className="h-6 w-6 text-teal-500 flex-shrink-0 mt-1" />
                <div>
                  <h3 className="text-lg font-semibold text-white">Human Oversight</h3>
                  <p>All AI systems maintain meaningful human control and oversight, ensuring accountability in decision-making processes.</p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <Brain className="h-6 w-6 text-teal-500 flex-shrink-0 mt-1" />
                <div>
                  <h3 className="text-lg font-semibold text-white">Bias Prevention</h3>
                  <p>We actively work to identify and eliminate biases in AI systems, promoting fair and equitable outcomes.</p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <AlertTriangle className="h-6 w-6 text-teal-500 flex-shrink-0 mt-1" />
                <div>
                  <h3 className="text-lg font-semibold text-white">Risk Assessment</h3>
                  <p>Regular evaluation of potential risks and impacts of AI implementations on individuals and society.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">Implementation Guidelines</h2>
            <ul className="list-disc list-inside text-gray-300 space-y-4">
              <li>Regular ethical impact assessments of AI systems</li>
              <li>Continuous monitoring of AI system behavior and outcomes</li>
              <li>Clear documentation of AI system capabilities and limitations</li>
              <li>Regular training for team members on ethical AI practices</li>
              <li>Established procedures for addressing ethical concerns</li>
              <li>Transparent communication with stakeholders</li>
            </ul>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">Prohibited Uses</h2>
            <p className="text-gray-300 mb-4">We explicitly prohibit the use of our AI systems for:</p>
            <ul className="list-disc list-inside text-gray-300 space-y-2">
              <li>Discrimination or bias amplification</li>
              <li>Manipulation or deception</li>
              <li>Privacy violations</li>
              <li>Harmful or malicious purposes</li>
              <li>Unauthorized surveillance</li>
            </ul>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">Governance and Oversight</h2>
            <p className="text-gray-300 mb-4">
              Our ethical governance structure includes:
            </p>
            <ul className="list-disc list-inside text-gray-300 space-y-2">
              <li>Ethics Review Board for AI implementations</li>
              <li>Regular audits of AI systems</li>
              <li>Clear escalation paths for ethical concerns</li>
              <li>Stakeholder engagement in ethical decision-making</li>
            </ul>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">Commitment to Improvement</h2>
            <p className="text-gray-300">
              We are committed to continuously improving our ethical framework through:
            </p>
            <ul className="list-disc list-inside text-gray-300 space-y-2 mt-4">
              <li>Regular review and updates of our ethical guidelines</li>
              <li>Engagement with industry experts and ethicists</li>
              <li>Incorporation of stakeholder feedback</li>
              <li>Monitoring of emerging ethical considerations in AI</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}