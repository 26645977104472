import React from 'react';
import SEO from '../components/SEO';
import AssessmentTool from '../components/assessment/AssessmentTool';
import AssessmentHero from '../components/assessment/AssessmentHero';
import NeuralBackground from '../components/NeuralBackground';

export default function AIReadinessAssessment() {
  return (
    <>
      <SEO 
        title="AI Readiness Assessment"
        description="Evaluate your organization's readiness for AI implementation with our comprehensive assessment tool. Get personalized insights and recommendations for successful AI adoption."
        keywords={[
          'AI Readiness',
          'Technology Assessment',
          'Digital Transformation',
          'Business Analysis',
          'AI Implementation',
          'Process Evaluation',
          'Technology Adoption',
          'Business Innovation',
          'AI Strategy',
          'Organizational Change'
        ]}
      />
      <div className="relative min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 pt-32 pb-20">
        <div className="absolute inset-0">
          <NeuralBackground />
        </div>
        <div className="relative">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <AssessmentHero />
            <AssessmentTool />
          </div>
        </div>
      </div>
    </>
  );
}