import React from 'react';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import Services from '../components/Services';
import Features from '../components/Features';
import Technologies from '../components/Technologies';
import WhatWeDo from '../components/WhatWeDo';
import CaseStudies from '../components/CaseStudies';
import ClientSuccess from '../components/success/ClientSuccess';

export default function Home() {
  return (
    <>
      <SEO 
        title="Enterprise AI Automation"
        description="Transform your business operations with AI-driven automation solutions. TacticalSolutions.ai empowers businesses of all sizes with accessible, intelligent automation."
        keywords={[
          'AI Automation',
          'Enterprise AI',
          'Business Process Automation',
          'Intelligent Automation',
          'AI Solutions',
          'Digital Transformation',
          'Process Optimization',
          'Business Intelligence',
          'Machine Learning',
          'Workflow Automation'
        ]}
      />
      <Hero />
      <Services />
      <Features />
      <WhatWeDo />
      <Technologies />
      <ClientSuccess />
      <CaseStudies />
    </>
  );
}