import React from 'react';
import { Brain, Network, Database, Bot, Cpu, Lock } from 'lucide-react';

const concepts = [
  {
    icon: Brain,
    title: 'Machine Learning',
    description: 'Systems that learn and improve from experience without explicit programming',
    examples: [
      'Pattern Recognition',
      'Predictive Analytics',
      'Automated Decision Making'
    ]
  },
  {
    icon: Network,
    title: 'Neural Networks',
    description: 'Computing systems inspired by biological neural networks in human brains',
    examples: [
      'Image Recognition',
      'Language Processing',
      'Data Classification'
    ]
  },
  {
    icon: Database,
    title: 'Big Data Processing',
    description: 'Handling and analyzing large volumes of structured and unstructured data',
    examples: [
      'Data Mining',
      'Real-time Analytics',
      'Trend Analysis'
    ]
  },
  {
    icon: Bot,
    title: 'Natural Language Processing',
    description: 'Enabling computers to understand and process human language',
    examples: [
      'Chatbots',
      'Text Analysis',
      'Language Translation'
    ]
  },
  {
    icon: Cpu,
    title: 'Deep Learning',
    description: 'Advanced machine learning using multiple layers of neural networks',
    examples: [
      'Voice Recognition',
      'Computer Vision',
      'Complex Pattern Detection'
    ]
  },
  {
    icon: Lock,
    title: 'AI Security',
    description: 'Protecting AI systems and data while ensuring ethical operation',
    examples: [
      'Data Protection',
      'Bias Prevention',
      'Ethical Compliance'
    ]
  }
];

export default function ConceptGrid() {
  return (
    <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
      <h2 className="text-4xl font-bold text-center mb-12">
        <span className="text-gradient">Core AI Concepts</span>
      </h2>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {concepts.map((concept, index) => (
          <div
            key={index}
            className="glass-card p-8 rounded-xl backdrop-blur-md transform hover:scale-105 transition-all duration-300"
          >
            <div className="flex items-center mb-6">
              <div className="w-12 h-12 rounded-lg bg-gradient-to-br from-teal-500/20 to-purple-500/20 p-2.5 flex items-center justify-center">
                <concept.icon className="h-6 w-6 text-teal-400" />
              </div>
              <h3 className="text-xl font-bold text-white ml-4">{concept.title}</h3>
            </div>
            
            <p className="text-gray-300 mb-6">{concept.description}</p>
            
            <div className="space-y-2">
              {concept.examples.map((example, i) => (
                <div key={i} className="flex items-center text-sm text-teal-400">
                  <div className="w-1.5 h-1.5 rounded-full bg-teal-500 mr-2" />
                  {example}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}