import React from 'react';
import { Shield, Lock, Eye } from 'lucide-react';

export default function PrivacyPolicy() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 pt-24 pb-20">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header Section */}
        <div className="text-center mb-12">
          <div className="flex justify-center mb-6">
            <Shield className="h-16 w-16 text-teal-500" />
          </div>
          <h1 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-teal-400 to-purple-400">
            Privacy Policy
          </h1>
          <p className="text-gray-400">Last Updated: November 25, 2024</p>
        </div>

        {/* Quick Links - Centered Grid */}
        <div className="max-w-3xl mx-auto mb-12">
          <div className="grid grid-cols-3 gap-6">
            <div className="glass-card p-6 rounded-xl backdrop-blur-md text-center">
              <Lock className="h-6 w-6 text-teal-500 mx-auto mb-3" />
              <span className="text-gray-300 text-sm">GDPR Compliant</span>
            </div>
            <div className="glass-card p-6 rounded-xl backdrop-blur-md text-center">
              <Eye className="h-6 w-6 text-teal-500 mx-auto mb-3" />
              <span className="text-gray-300 text-sm">Data Transparency</span>
            </div>
            <div className="glass-card p-6 rounded-xl backdrop-blur-md text-center">
              <Shield className="h-6 w-6 text-teal-500 mx-auto mb-3" />
              <span className="text-gray-300 text-sm">Clear Guidelines</span>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="space-y-8">
          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">1. Introduction</h2>
            <div className="text-gray-300 space-y-4">
              <p>
                Welcome to TacticalSolutions.ai. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information when you use our services, website, and applications (collectively, the "Services").
              </p>
              <p>
                We are committed to protecting your personal data and complying with the UK Data Protection Act 2018, the UK GDPR, and the EU General Data Protection Regulation (GDPR).
              </p>
            </div>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">2. Data Controller Information</h2>
            <p className="text-gray-300">
              TacticalSolutions.ai acts as the data controller for the personal information we collect. For any privacy-related queries, you can contact our Data Protection Officer through our website contact form at TacticalSolutions.ai.
            </p>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">3. Information We Collect</h2>
            
            <div className="mb-8">
              <h3 className="text-xl font-semibold text-teal-400 mb-4">3.1 Personal Information You Provide</h3>
              <ul className="list-disc list-inside text-gray-300 space-y-2 ml-4">
                <li>Name and contact details</li>
                <li>Login credentials</li>
                <li>Payment information</li>
                <li>Communication preferences</li>
                <li>Any other information you choose to provide</li>
              </ul>
            </div>

            <div>
              <h3 className="text-xl font-semibold text-teal-400 mb-4">3.2 Information Automatically Collected</h3>
              <ul className="list-disc list-inside text-gray-300 space-y-2 ml-4">
                <li>Device information (IP address, browser type, operating system)</li>
                <li>Usage data and analytics</li>
                <li>Cookies and similar tracking technologies</li>
                <li>Log files and performance data</li>
              </ul>
            </div>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">4. Legal Basis for Processing</h2>
            <p className="text-gray-300 mb-4">We process your personal data under the following legal bases:</p>
            <ul className="list-disc list-inside text-gray-300 space-y-2 ml-4">
              <li>Contractual necessity - to provide our services</li>
              <li>Legal obligation - to comply with applicable laws</li>
              <li>Legitimate interests - to improve our services and protect our business interests</li>
              <li>Consent - where specifically requested and provided by you</li>
            </ul>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">5. How We Use Your Information</h2>
            <ul className="list-disc list-inside text-gray-300 space-y-2 ml-4">
              <li>Provide and maintain our Services</li>
              <li>Process your transactions</li>
              <li>Send administrative information</li>
              <li>Provide customer support</li>
              <li>Send marketing communications (with consent)</li>
              <li>Improve and optimize our Services</li>
              <li>Detect and prevent fraud</li>
            </ul>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">6. Data Sharing and Disclosure</h2>
            <p className="text-gray-300 mb-4">We may share your information with:</p>
            <ul className="list-disc list-inside text-gray-300 space-y-2 ml-4">
              <li>Service providers and business partners</li>
              <li>Legal authorities when required by law</li>
              <li>Professional advisers</li>
              <li>In connection with a business transaction</li>
            </ul>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">7. International Data Transfers</h2>
            <p className="text-gray-300 mb-4">
              When we transfer personal data outside the UK or EEA, we ensure appropriate safeguards are in place through:
            </p>
            <ul className="list-disc list-inside text-gray-300 space-y-2 ml-4">
              <li>Standard Contractual Clauses</li>
              <li>Adequacy decisions</li>
              <li>Other legally approved transfer mechanisms</li>
            </ul>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">8. Data Security</h2>
            <p className="text-gray-300 mb-4">
              We implement appropriate technical and organizational measures to protect your personal data, including:
            </p>
            <ul className="list-disc list-inside text-gray-300 space-y-2 ml-4">
              <li>Encryption of data in transit and at rest</li>
              <li>Access controls and authentication</li>
              <li>Regular security assessments</li>
              <li>Staff training on data protection</li>
            </ul>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">9. Data Retention</h2>
            <p className="text-gray-300 mb-4">We retain your personal data for as long as necessary to:</p>
            <ul className="list-disc list-inside text-gray-300 space-y-2 ml-4">
              <li>Provide our Services</li>
              <li>Comply with legal obligations</li>
              <li>Resolve disputes</li>
              <li>Enforce our agreements</li>
            </ul>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">10. Your Rights</h2>
            <p className="text-gray-300 mb-4">Under data protection laws, you have the right to:</p>
            <ul className="list-disc list-inside text-gray-300 space-y-2 ml-4">
              <li>Access your personal data</li>
              <li>Rectify inaccurate data</li>
              <li>Request erasure of your data</li>
              <li>Restrict or object to processing</li>
              <li>Data portability</li>
              <li>Withdraw consent</li>
              <li>Lodge a complaint with a supervisory authority</li>
            </ul>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">11. Cookie Policy</h2>
            <p className="text-gray-300 mb-4">We use cookies and similar technologies to:</p>
            <ul className="list-disc list-inside text-gray-300 space-y-2 ml-4">
              <li>Maintain session information</li>
              <li>Remember your preferences</li>
              <li>Analyze website traffic</li>
              <li>Personalize content</li>
            </ul>
            <p className="text-gray-300 mt-4">
              You can control cookie settings through your browser preferences.
            </p>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">12. Children's Privacy</h2>
            <p className="text-gray-300">
              Our Services are not intended for children under 16. We do not knowingly collect personal information from children under 16. If we become aware that we have collected personal data from a child under 16, we will take steps to delete such information.
            </p>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">13. Changes to This Privacy Policy</h2>
            <p className="text-gray-300">
              We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date.
            </p>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">14. Contact Us</h2>
            <div className="text-gray-300 space-y-4">
              <p>
                If you have any questions about this Privacy Policy or our data practices, please contact us through our website contact form at TacticalSolutions.ai
              </p>
              <div className="mt-4 space-y-2">
                <p><strong className="text-teal-400">Data Controller:</strong> Anthony Keen</p>
                <p><strong className="text-teal-400">DPO Contact:</strong> Available through website contact form</p>
                <p><strong className="text-teal-400">Supervisory Authority:</strong> Information Commissioner's Office (ICO)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}