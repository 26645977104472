import React from 'react';
import NavLinks from './NavLinks';
import { MobileMenuProps } from './types';

export default function MobileMenu({
  isMenuOpen,
  isScrolled,
  handleHomeClick,
  scrollToSection,
  setIsMenuOpen
}: MobileMenuProps) {
  const mobileLinkClasses = "block w-full py-2 text-left text-gray-900 dark:text-white hover:text-teal-500 dark:hover:text-teal-400 transition-colors";

  return (
    <div 
      className={`md:hidden transition-all duration-300 ease-in-out ${
        isMenuOpen 
          ? 'max-h-96 opacity-100 bg-white/95 dark:bg-gray-900/95 backdrop-blur-md shadow-lg'
          : 'max-h-0 opacity-0'
      } overflow-hidden`}
    >
      <div className="py-4 space-y-4 px-4">
        <NavLinks
          linkClasses={mobileLinkClasses}
          handleHomeClick={handleHomeClick}
          scrollToSection={scrollToSection}
          setIsMenuOpen={setIsMenuOpen}
        />
      </div>
    </div>
  );
}