import React from 'react';

export default function AboutHero() {
  return (
    <div className="relative pt-32 pb-12">
      <div className="absolute inset-0 bg-gradient-to-b from-gray-900/95 via-gray-900/90 to-gray-900/85" />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <div className="text-center">
          <h1 className="text-5xl md:text-7xl font-bold mb-6">
            <span className="text-gradient">From Personal Journey to Digital Innovation</span>
          </h1>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto">
            A story of transformation, resilience, and technological innovation
          </p>
        </div>
      </div>
    </div>
  );
}