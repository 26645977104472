import { TrendingUp, Clock, DollarSign, Users, BarChart } from 'lucide-react';
import type { SuccessStory } from './types';

export const successStories: SuccessStory[] = [
  {
    company: 'Nordisk Logistik AB',
    location: 'Stockholm, Sweden',
    industry: 'Logistics & Supply Chain',
    challenge: 'Managing complex supply chain operations across Nordic countries with manual processes causing delays and errors.',
    solution: 'Implemented AI-driven automation for route optimization, inventory management, and predictive maintenance.',
    results: [
      { icon: Clock, metric: '68%', label: 'Reduction in processing time' },
      { icon: DollarSign, metric: '€2.4M', label: 'Annual cost savings' },
      { icon: TrendingUp, metric: '99.8%', label: 'Delivery accuracy' }
    ],
    quote: {
      text: "The AI automation solutions have transformed our operations. We've seen dramatic improvements in efficiency and customer satisfaction.",
      author: "Erik Johansson",
      role: "Chief Operations Officer"
    }
  },
  {
    company: 'Kanpai Healthcare',
    location: 'Osaka, Japan',
    industry: 'Healthcare Technology',
    challenge: 'Processing thousands of medical records and insurance claims daily with high error rates and significant backlogs.',
    solution: 'Deployed intelligent document processing and automated workflow systems integrated with existing healthcare platforms.',
    results: [
      { icon: TrendingUp, metric: '94%', label: 'Processing accuracy' },
      { icon: Clock, metric: '71%', label: 'Faster processing' },
      { icon: Users, metric: '15,000', label: 'Daily claims processed' }
    ],
    quote: {
      text: "The automation system has revolutionized our claims processing. Our staff can now focus on patient care rather than paperwork.",
      author: "Tanaka Hiroshi",
      role: "Director of Operations"
    }
  },
  {
    company: 'Southern Cross Financial',
    location: 'Melbourne, Australia',
    industry: 'Financial Services',
    challenge: 'Managing risk assessment and compliance monitoring across multiple financial products and jurisdictions.',
    solution: 'Implemented AI-powered risk assessment and compliance monitoring system with real-time alerts and reporting.',
    results: [
      { icon: Clock, metric: '85%', label: 'Faster risk assessments' },
      { icon: BarChart, metric: '99.9%', label: 'Compliance accuracy' },
      { icon: DollarSign, metric: '$3.2M', label: 'Regulatory savings' }
    ],
    quote: {
      text: "The AI system has transformed our risk management capabilities. We can now identify and respond to potential issues in real-time.",
      author: "Sarah Mitchell",
      role: "Head of Risk & Compliance"
    }
  }
];