import React from 'react';
import type { Result } from './types';

interface StoryResultsProps {
  results: Result[];
}

export default function StoryResults({ results }: StoryResultsProps) {
  return (
    <div className="relative">
      <div className="absolute inset-0 bg-gradient-to-br from-teal-500/5 to-purple-500/5 rounded-xl" />
      <div className="relative glass-card p-8 rounded-xl backdrop-blur-md bg-white/5">
        <h4 className="text-xl font-bold text-white mb-8">Impact & Results</h4>
        <div className="grid gap-8">
          {results.map((result, i) => (
            <div key={i} className="flex items-center space-x-4">
              <div className="w-12 h-12 rounded-lg bg-gradient-to-br from-teal-500/20 to-purple-500/20 p-2.5 flex items-center justify-center">
                <result.icon className="h-6 w-6 text-teal-400" />
              </div>
              <div>
                <div className="text-3xl font-bold text-white">{result.metric}</div>
                <div className="text-sm text-gray-400">{result.label}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}