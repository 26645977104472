import React from 'react';
import { Users, Heart, Star } from 'lucide-react';

export default function CareerHero() {
  return (
    <div className="relative py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-5xl md:text-7xl font-bold mb-6">
            <span className="text-gradient">Join Our Mission</span>
          </h1>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto mb-12">
            Help us revolutionize business automation and empower companies worldwide through innovative AI solutions.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-4xl mx-auto">
            <div className="glass-card p-6 rounded-xl backdrop-blur-md">
              <Users className="h-8 w-8 text-teal-400 mx-auto mb-4" />
              <h3 className="text-xl font-bold text-white mb-2">Dynamic Team</h3>
              <p className="text-gray-300">Join a diverse group of innovators and problem-solvers</p>
            </div>
            <div className="glass-card p-6 rounded-xl backdrop-blur-md">
              <Heart className="h-8 w-8 text-teal-400 mx-auto mb-4" />
              <h3 className="text-xl font-bold text-white mb-2">Great Benefits</h3>
              <p className="text-gray-300">Competitive packages and work-life balance</p>
            </div>
            <div className="glass-card p-6 rounded-xl backdrop-blur-md">
              <Star className="h-8 w-8 text-teal-400 mx-auto mb-4" />
              <h3 className="text-xl font-bold text-white mb-2">Growth</h3>
              <p className="text-gray-300">Continuous learning and career development</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}