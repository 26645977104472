import React from 'react';
import { ClipboardCheck, Brain, Shield } from 'lucide-react';

export default function AssessmentHero() {
  return (
    <div className="text-center max-w-3xl mx-auto mb-16">
      <h1 className="text-5xl font-bold mb-6">
        <span className="text-gradient">AI Readiness Assessment</span>
      </h1>
      <p className="text-xl text-gray-300 mb-12">
        Evaluate your organization's readiness for AI implementation with our comprehensive 5-minute assessment
      </p>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="glass-card p-6 rounded-xl backdrop-blur-md">
          <ClipboardCheck className="h-8 w-8 text-teal-400 mx-auto mb-4" />
          <h3 className="text-white font-semibold mb-2">5-Minute Assessment</h3>
          <p className="text-gray-400 text-sm">Quick and comprehensive evaluation</p>
        </div>
        <div className="glass-card p-6 rounded-xl backdrop-blur-md">
          <Brain className="h-8 w-8 text-teal-400 mx-auto mb-4" />
          <h3 className="text-white font-semibold mb-2">Expert Analysis</h3>
          <p className="text-gray-400 text-sm">Based on industry standards</p>
        </div>
        <div className="glass-card p-6 rounded-xl backdrop-blur-md">
          <Shield className="h-8 w-8 text-teal-400 mx-auto mb-4" />
          <h3 className="text-white font-semibold mb-2">Actionable Insights</h3>
          <p className="text-gray-400 text-sm">Clear next steps and recommendations</p>
        </div>
      </div>
    </div>
  );
}