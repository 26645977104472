interface Tip {
  tip: string;
  details: string;
}

export function getActionableTips(score: number): Tip[] {
  if (score >= 80) {
    return [
      {
        tip: 'Document your automation success metrics',
        details: 'Create a dashboard tracking key metrics like time saved, error reduction, and cost savings. This data will help secure buy-in for future AI initiatives.'
      },
      {
        tip: 'Create a change management plan',
        details: 'Develop a structured approach to implementing AI solutions, including staff training, communication strategies, and feedback mechanisms.'
      },
      {
        tip: 'Consider pilot programs for new AI initiatives',
        details: 'Start with small, controlled implementations to demonstrate value and gather real-world performance data before scaling.'
      },
      {
        tip: 'Develop a data governance framework',
        details: 'Establish clear policies for data quality, security, and accessibility to ensure your AI systems have reliable information.'
      }
    ];
  }
  
  if (score >= 60) {
    return [
      {
        tip: 'Start with process mapping',
        details: 'Document and analyze your workflows to identify the highest-impact opportunities for automation and efficiency improvements.'
      },
      {
        tip: 'Implement data validation rules',
        details: 'Set up automated checks to ensure data accuracy and consistency, reducing errors and improving AI system performance.'
      },
      {
        tip: 'Train key team members',
        details: 'Identify and upskill staff who will champion automation initiatives and help drive adoption across the organization.'
      },
      {
        tip: 'Begin documenting tribal knowledge',
        details: 'Capture undocumented processes and best practices to ensure consistent operations and easier automation implementation.'
      }
    ];
  }
  
  return [
    {
      tip: 'Focus on standardizing critical processes',
      details: 'Begin by documenting and standardizing your most important workflows. This creates a foundation for future automation.'
    },
    {
      tip: 'Create basic documentation',
      details: 'Start documenting repetitive tasks, including steps, decisions, and common issues. This will highlight automation opportunities.'
    },
    {
      tip: 'Identify efficiency bottlenecks',
      details: 'Track where time is being spent and errors occur most frequently. This data will help prioritize automation efforts.'
    },
    {
      tip: 'Start collecting key business data',
      details: 'Begin systematically gathering data about your operations. Good data is essential for successful AI implementation.'
    }
  ];
}