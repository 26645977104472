import React from 'react';
import { ArrowRight, Bot, Brain, Search, Zap } from 'lucide-react';
import { Link } from 'react-router-dom';

const useCases = [
  {
    icon: Bot,
    title: 'Customer Service',
    description: 'AI-powered chatbots and virtual assistants that provide 24/7 customer support, handle inquiries, and route complex issues to human agents.',
    metrics: ['90% faster response times', '24/7 availability', '70% cost reduction']
  },
  {
    icon: Brain,
    title: 'Process Automation',
    description: 'Intelligent automation of repetitive tasks, document processing, and workflow management to improve efficiency and reduce errors.',
    metrics: ['85% error reduction', '60% faster processing', '40% cost savings']
  },
  {
    icon: Search,
    title: 'Data Analysis',
    description: 'Advanced analytics and pattern recognition to extract insights from large datasets and make data-driven decisions.',
    metrics: ['95% accuracy rate', 'Real-time insights', 'Predictive capabilities']
  },
  {
    icon: Zap,
    title: 'Operational Optimization',
    description: 'AI-driven optimization of business operations, resource allocation, and supply chain management.',
    metrics: ['30% efficiency gain', '25% waste reduction', 'Improved forecasting']
  }
];

export default function UseCases() {
  return (
    <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
      <h2 className="text-4xl font-bold text-center mb-12">
        <span className="text-gradient">Real-World Applications</span>
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
        {useCases.map((useCase, index) => (
          <div
            key={index}
            className="glass-card p-8 rounded-xl backdrop-blur-md transform hover:scale-105 transition-all duration-300"
          >
            <div className="flex items-center mb-6">
              <div className="w-12 h-12 rounded-lg bg-gradient-to-br from-teal-500/20 to-purple-500/20 p-2.5 flex items-center justify-center">
                <useCase.icon className="h-6 w-6 text-teal-400" />
              </div>
              <h3 className="text-xl font-bold text-white ml-4">{useCase.title}</h3>
            </div>
            
            <p className="text-gray-300 mb-6">{useCase.description}</p>
            
            <div className="space-y-2">
              {useCase.metrics.map((metric, i) => (
                <div key={i} className="flex items-center text-sm text-teal-400">
                  <div className="w-1.5 h-1.5 rounded-full bg-teal-500 mr-2" />
                  {metric}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className="text-center">
        <Link 
          to="/contact" 
          className="inline-flex items-center px-8 py-4 text-lg font-medium rounded-md text-white bg-gradient-to-r from-teal-500 to-teal-600 hover:from-teal-600 hover:to-teal-700 transition-all duration-200 shadow-lg hover:shadow-xl"
        >
          <span>Explore AI Solutions for Your Business</span>
          <ArrowRight className="ml-2 h-5 w-5" />
        </Link>
      </div>
    </section>
  );
}