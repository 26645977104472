import type { Question } from './types';

export const questions: Question[] = [
  {
    text: "How are your business processes currently documented and managed?",
    category: "Process Readiness",
    options: [
      {
        text: "No formal documentation",
        description: "TIP: Start by documenting your most critical processes - this is the first step toward successful automation",
        score: 1
      },
      {
        text: "Basic documentation exists",
        description: "TIP: Consider using process mapping tools to visualize and standardize your workflows",
        score: 2
      },
      {
        text: "Documented but not standardized",
        description: "TIP: Standardizing processes across teams can increase your automation success by 40%",
        score: 3
      },
      {
        text: "Well documented and standardized",
        description: "TIP: You're well-positioned for automation. Consider process mining to identify optimization opportunities",
        score: 4
      },
      {
        text: "Fully documented with regular updates",
        description: "TIP: Your mature documentation practices are ideal for AI implementation",
        score: 5
      }
    ]
  },
  {
    text: "What is your current level of data quality and accessibility?",
    category: "Data Readiness",
    options: [
      {
        text: "Data is scattered and inconsistent",
        description: "TIP: Start with a data audit and centralization strategy - this can reduce processing time by 50%",
        score: 1
      },
      {
        text: "Basic data organization",
        description: "TIP: Implementing data validation rules can reduce errors by up to 80%",
        score: 2
      },
      {
        text: "Structured but siloed data",
        description: "TIP: Breaking down data silos can improve decision-making speed by 60%",
        score: 3
      },
      {
        text: "Well-structured, accessible data",
        description: "TIP: Consider automated data quality monitoring to maintain high standards",
        score: 4
      },
      {
        text: "Clean, integrated data systems",
        description: "TIP: Your data maturity is perfect for advanced AI applications",
        score: 5
      }
    ]
  },
  {
    text: "How does your team currently handle repetitive tasks?",
    category: "Operational Efficiency",
    options: [
      {
        text: "Entirely manual processes",
        description: "TIP: Automating basic tasks can save 40+ hours per employee monthly",
        score: 1
      },
      {
        text: "Basic tools and spreadsheets",
        description: "TIP: Upgrading to automated workflows can reduce processing time by 70%",
        score: 2
      },
      {
        text: "Some automation tools",
        description: "TIP: Integrating your existing tools can increase efficiency by 50%",
        score: 3
      },
      {
        text: "Multiple automation solutions",
        description: "TIP: Centralizing automation can improve overall efficiency by 40%",
        score: 4
      },
      {
        text: "Integrated automation systems",
        description: "TIP: AI can help optimize your existing automation for even better results",
        score: 5
      }
    ]
  },
  {
    text: "What is your team's current workload and capacity?",
    category: "Resource Optimization",
    options: [
      {
        text: "Consistently overworked",
        description: "TIP: Automation can reduce manual workload by 80% in high-volume tasks",
        score: 1
      },
      {
        text: "Frequently at maximum capacity",
        description: "TIP: AI automation can help reduce overtime by 60%",
        score: 2
      },
      {
        text: "Manageable but busy",
        description: "TIP: Automated workflows can free up 30% of your team's time",
        score: 3
      },
      {
        text: "Well-balanced workload",
        description: "TIP: AI can help maintain this balance while scaling operations",
        score: 4
      },
      {
        text: "Optimal resource utilization",
        description: "TIP: AI can help you maintain efficiency while growing",
        score: 5
      }
    ]
  },
  {
    text: "How do you currently measure and improve process performance?",
    category: "Performance Monitoring",
    options: [
      {
        text: "No formal measurements",
        description: "TIP: Implementing basic metrics can identify 30% cost saving opportunities",
        score: 1
      },
      {
        text: "Basic performance tracking",
        description: "TIP: Automated monitoring can spot improvement opportunities worth 40% in savings",
        score: 2
      },
      {
        text: "Regular performance reviews",
        description: "TIP: AI analytics can predict issues before they impact performance",
        score: 3
      },
      {
        text: "Continuous monitoring",
        description: "TIP: AI can automate optimization based on your monitoring data",
        score: 4
      },
      {
        text: "Advanced analytics and optimization",
        description: "TIP: AI can enhance your analytics with predictive insights",
        score: 5
      }
    ]
  }
];