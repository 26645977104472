import React from 'react';
import { Scale, FileText, AlertCircle } from 'lucide-react';

export default function TermsOfService() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 pt-24 pb-20">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header Section */}
        <div className="text-center mb-12">
          <div className="flex justify-center mb-6">
            <Scale className="h-16 w-16 text-teal-500" />
          </div>
          <h1 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-teal-400 to-purple-400">
            Terms of Service
          </h1>
          <p className="text-gray-400">Last Updated: November 25, 2024</p>
        </div>

        {/* Quick Links - Centered Grid */}
        <div className="max-w-3xl mx-auto mb-12">
          <div className="grid grid-cols-3 gap-6">
            <div className="glass-card p-6 rounded-xl backdrop-blur-md text-center">
              <Scale className="h-6 w-6 text-teal-500 mx-auto mb-3" />
              <span className="text-gray-300 text-sm">GDPR Compliant</span>
            </div>
            <div className="glass-card p-6 rounded-xl backdrop-blur-md text-center">
              <FileText className="h-6 w-6 text-teal-500 mx-auto mb-3" />
              <span className="text-gray-300 text-sm">Clear Terms</span>
            </div>
            <div className="glass-card p-6 rounded-xl backdrop-blur-md text-center">
              <AlertCircle className="h-6 w-6 text-teal-500 mx-auto mb-3" />
              <span className="text-gray-300 text-sm">User Rights</span>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="space-y-8">
          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">1. Introduction</h2>
            <p className="text-gray-300">
              Welcome to TacticalSolutions.ai. By accessing or using our website and services, you agree to be bound by these Terms of Service ("Terms"). Please read these terms carefully before using our services.
            </p>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">2. Definitions</h2>
            <ul className="space-y-4 text-gray-300">
              <li><strong className="text-teal-400">"Service"</strong> refers to the website, applications, and services provided by TacticalSolutions.ai</li>
              <li><strong className="text-teal-400">"User," "you," and "your"</strong> refer to any individual or entity using our Service</li>
              <li><strong className="text-teal-400">"We," "us," and "our"</strong> refer to TacticalSolutions.ai</li>
            </ul>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">3. GDPR Compliance</h2>
            <p className="text-gray-300 mb-4">We comply with the General Data Protection Regulation (GDPR) and other applicable EU data protection laws. Our data processing activities are conducted in accordance with the following principles:</p>
            <ul className="list-disc list-inside text-gray-300 space-y-2">
              <li>Lawfulness, fairness, and transparency</li>
              <li>Purpose limitation</li>
              <li>Data minimization</li>
              <li>Accuracy</li>
              <li>Storage limitation</li>
              <li>Integrity and confidentiality</li>
            </ul>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">4. User Rights Under GDPR</h2>
            <p className="text-gray-300 mb-4">Users located in the EU have the following rights:</p>
            <ul className="list-disc list-inside text-gray-300 space-y-2">
              <li>Right to access personal data</li>
              <li>Right to rectification</li>
              <li>Right to erasure ("right to be forgotten")</li>
              <li>Right to restrict processing</li>
              <li>Right to data portability</li>
              <li>Right to object</li>
            </ul>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">5. Service Usage</h2>
            <p className="text-gray-300 mb-4">By using our Service, you agree to:</p>
            <ul className="list-disc list-inside text-gray-300 space-y-2">
              <li>Provide accurate and complete information</li>
              <li>Maintain the security of your account</li>
              <li>Not use the Service for any illegal purposes</li>
              <li>Not violate any intellectual property rights</li>
            </ul>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">6. Intellectual Property</h2>
            <p className="text-gray-300">
              All content, features, and functionality of the Service are owned by TacticalSolutions.ai and are protected by international copyright, trademark, patent, trade secret, and other intellectual property laws.
            </p>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">7. Privacy Policy</h2>
            <p className="text-gray-300">
              Our Privacy Policy, which describes how we collect, use, and share your personal information, is an integral part of these Terms.
            </p>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">8. Service Modifications</h2>
            <p className="text-gray-300">
              We reserve the right to modify or discontinue the Service at any time, with or without notice. We shall not be liable to you or any third party for any modification, suspension, or discontinuance of the Service.
            </p>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">9. Limitation of Liability</h2>
            <p className="text-gray-300">
              To the maximum extent permitted by law, TacticalSolutions.ai shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of the Service.
            </p>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">10. Termination</h2>
            <p className="text-gray-300">
              We reserve the right to terminate or suspend your access to the Service immediately, without prior notice, for any breach of these Terms.
            </p>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">11. Governing Law</h2>
            <p className="text-gray-300">
              These Terms shall be governed by and construed in accordance with the laws of the European Union and applicable member state laws.
            </p>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">12. Changes to Terms</h2>
            <p className="text-gray-300">
              We reserve the right to modify these Terms at any time. We will notify users of any material changes via email or through the Service.
            </p>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">13. Contact Information</h2>
            <p className="text-gray-300">
              For any questions about these Terms, please contact us at TacticalSolutions.ai
            </p>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">14. Severability</h2>
            <p className="text-gray-300">
              If any provision of these Terms is found to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that these Terms shall otherwise remain in full force and effect.
            </p>
          </div>

          <div className="glass-card p-8 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">15. Agreement</h2>
            <p className="text-gray-300">
              By using our Service, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}