import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, Brain, FileText, BarChart, Bot, Network, Truck, Workflow, Users, BookOpen } from 'lucide-react';

const services = [
  {
    icon: Brain,
    title: 'Process Intelligence',
    description: 'Enhance your existing workflows with AI-powered insights and optimization.',
    features: [
      'Workflow Analysis',
      'Performance Optimization',
      'Efficiency Monitoring',
      'Impact Assessment'
    ]
  },
  {
    icon: FileText,
    title: 'Document Automation',
    description: 'Automate document processing within your current systems and workflows.',
    features: [
      'Automated Processing',
      'Data Extraction',
      'System Integration',
      'Accuracy Verification'
    ]
  },
  {
    icon: BarChart,
    title: 'Business Analytics',
    description: 'Enhance your existing analytics tools with AI-powered insights and predictions.',
    features: [
      'Data Integration',
      'Predictive Analysis',
      'Custom Reporting',
      'Automated Alerts'
    ]
  },
  {
    icon: Bot,
    title: 'Customer Service AI',
    description: 'Augment your support team with intelligent automation solutions.',
    features: [
      'Support Integration',
      'Channel Connection',
      'Response Automation',
      'Team Collaboration'
    ]
  },
  {
    icon: Truck,
    title: 'Supply Chain Automation',
    description: 'Optimize your existing supply chain operations with intelligent automation.',
    features: [
      'Inventory Integration',
      'Demand Analysis',
      'Logistics Optimization',
      'Supplier Coordination'
    ]
  },
  {
    icon: Network,
    title: 'System Integration',
    description: 'Connect and enhance your existing business systems with AI capabilities.',
    features: [
      'Custom Integration',
      'System Connectivity',
      'Data Synchronization',
      'Performance Monitoring'
    ]
  },
  {
    icon: Workflow,
    title: 'Workflow Automation',
    description: 'Add intelligent automation to your current business processes.',
    features: [
      'Process Enhancement',
      'Custom Automation',
      'Seamless Integration',
      'Performance Tracking'
    ]
  },
  {
    icon: Users,
    title: 'AI Consultation',
    description: 'Expert guidance on implementing AI within your existing infrastructure.',
    features: [
      'System Assessment',
      'Integration Planning',
      'Implementation Support',
      'Ongoing Optimization'
    ]
  }
];

export default function Services() {
  return (
    <section id="services" className="py-24 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-20">
          <h2 className="text-4xl font-bold mb-4">
            <span className="text-gradient">Accessible AI Solutions</span>
          </h2>
          <p className="text-xl text-gray-600">
            Enhancing your existing systems with powerful AI capabilities
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {services.map((service, index) => (
            <div
              key={index}
              className="group perspective-container"
            >
              <div className="relative bg-white shadow-lg rounded-xl p-8 transition-all duration-500 transform-gpu card-3d h-full hover:shadow-xl border border-gray-100">
                <div className="absolute inset-0 bg-gradient-to-br from-teal-500/0 to-purple-500/0 group-hover:from-teal-500/5 group-hover:to-purple-500/5 rounded-xl transition-all duration-500" />
                
                <div className="relative z-10 flex flex-col h-full">
                  <div className="w-14 h-14 rounded-xl bg-teal-500/10 flex items-center justify-center mb-6 group-hover:scale-110 transition-transform duration-500 flex-shrink-0">
                    <service.icon className="h-7 w-7 text-teal-600" />
                  </div>

                  <h3 className="text-xl font-bold text-gray-900 mb-3 group-hover:text-teal-600">
                    {service.title}
                  </h3>
                  
                  <p className="text-gray-600 mb-6 flex-grow">
                    {service.description}
                  </p>

                  <div className="space-y-2">
                    {service.features.map((feature, i) => (
                      <div key={i} className="flex items-center text-sm text-teal-600">
                        <div className="w-1.5 h-1.5 rounded-full bg-teal-500 mr-2" />
                        {feature}
                      </div>
                    ))}
                  </div>

                  <div className="magnetic-glow" />
                </div>

                <div className="card-shadow" />
              </div>
            </div>
          ))}
        </div>

        <div className="text-center mt-16">
          <Link 
            to="/ai-explainers"
            className="inline-flex items-center px-8 py-4 text-lg font-medium rounded-md text-white bg-gradient-to-r from-teal-500 to-teal-600 hover:from-teal-600 hover:to-teal-700 transition-colors duration-200 shadow-lg hover:shadow-xl"
          >
            <BookOpen className="h-5 w-5 mr-2" />
            <span>Learn More About AI Technology</span>
            <ArrowRight className="ml-2 h-5 w-5" />
          </Link>
        </div>
      </div>
    </section>
  );
}