import React from 'react';

export default function TeamCulture() {
  return (
    <section className="py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="glass-card p-8 rounded-xl backdrop-blur-md">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-4xl font-bold mb-6">
              <span className="text-gradient">Our Culture</span>
            </h2>
            <div className="space-y-6 text-lg text-gray-300">
              <p>
                At TacticalSolutions.ai, we believe in fostering an environment where innovation thrives and every team member can make a significant impact. Our culture is built on collaboration, continuous learning, and the pursuit of excellence.
              </p>
              <p>
                We're a remote-first company that values work-life balance, diversity of thought, and the unique perspectives each team member brings to the table. Join us in our mission to revolutionize business automation through AI.
              </p>
              <blockquote className="border-l-4 border-teal-500 pl-4 my-6 italic text-gray-200">
                "We're not just building technology; we're building a team that's passionate about solving real-world problems through innovation."
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}